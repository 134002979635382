import React, { ReactNode } from "react";
import styled from "styled-components";

import { Drawer } from "@global";
import { assets, colorsRGB, fontWeights } from "@util/constants";
import { Container } from "@util/standard";

interface Props {
  title?: string;
  visible: boolean;
  onClose: () => void;
  children: ReactNode;
}

const MobileFilterContainer = styled(Container)`
  position: fixed;
  bottom: 0px;
  height: 80vh;
  width: 100%;
  background-color: white;

  h3 {
    margin: 0 0 20px 0;
    font-weight: ${fontWeights.medium};
  }
`;

const HeaderContainer = styled(Container)`
  padding-bottom: 35px;
  border-bottom: 1px solid ${colorsRGB.navy(0.4)};
  width: 100%;

  margin-top: 10%;

  img {
    margin-right: 12px;
  }
  h4 {
    margin: 0px;
  }
`;

function MobileFilterList({ title, visible, onClose, children }: Props) {
  return (
    <Drawer width="100%" height="80%" visible={visible} placement="bottom" onClose={onClose}>
      <MobileFilterContainer>
        <Container
          width="100%"
          height="100%"
          flexDirection="column"
          padding="0 10% 10% 10%"
          position="relative"
        >
          <HeaderContainer>
            <img src={assets.filterIcon} />
            <h4>{title ?? "Filter"}</h4>
          </HeaderContainer>
          {children}
        </Container>
      </MobileFilterContainer>
    </Drawer>
  );
}

export default MobileFilterList;
