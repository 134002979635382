import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const FooterQuery = () => {
  const { allSanityFooter }: Query = useStaticQuery(graphql`
    query FooterQuery {
      allSanityFooter {
        nodes {
          region {
            countryCode
          }
          footerCtas {
            ctas {
              ...sanityCta
            }
          }
          menuLinks {
            _key
            menuLinks {
              ...sanityLink
            }
            menuTitle
          }
          micrositeMenuLinks {
            _key
            menuLinks {
              ...sanityLink
            }
            menuTitle
          }
          footerSocialLinks {
            _key
            socialLink {
              link {
                ...sanityLink
              }
              image {
                ...sanityFullWidthImage
              }
            }
          }
          footerFormText
        }
      }
    }
  `);

  if (allSanityFooter == null) return null;

  const regionFooter = allSanityFooter.nodes.filter(footer => {
    if (footer == null) return null;

    return footer.region?.countryCode === process.env.GATSBY_REGION;
  });

  if (regionFooter[0] == null) return allSanityFooter.nodes[0];

  return regionFooter[0];
};

export default FooterQuery;
