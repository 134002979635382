import React, { ReactNode } from "react";
import styled from "styled-components";

import { Maybe, SanityBlockContent } from "@graphql-types";
import { Container, GridContainer, MobileContainer } from "@util/standard";
import { colorsRGB, TABLET_BREAKPOINT } from "@util/constants";
import { BlocksContent } from "@global";

interface Props {
  textData?: Maybe<SanityBlockContent> | undefined;
  noBorderTop?: boolean;
  children?: ReactNode;
  mobileChildren?: ReactNode;

  index?: number;
}

const StyledContainer = styled(Container)<{ noBorderTop?: boolean }>`
  border: 1px solid ${colorsRGB.navy(0.4)};
  border-right: none;
  width: 100%;

  ${({ noBorderTop }) => noBorderTop && `border-top: none;`};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    border: none;
  }
`;

const TextContainer = styled.div<{ isFirst?: boolean }>`
  padding: 50px 13%;
  border-left: 1px solid ${colorsRGB.navy(0.4)};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ isFirst }) => (isFirst ? "padding: 10px 0 70px 0;" : "padding: 70px 0px;")};
    width: 80%;
    margin: 0 auto;
    border-left: none;
    border-bottom: 1px solid ${colorsRGB.navy(0.4)};
  }
`;

function StandardGridLayout({ mobileChildren, index, textData, noBorderTop, children }: Props) {
  return (
    <>
      {textData && (
        <TextContainer isFirst={index === 0}>
          <BlocksContent data={textData} />
        </TextContainer>
      )}
      {mobileChildren && (
        <MobileContainer tabletWidth="80%" tabletMargin="auto">
          {mobileChildren}
        </MobileContainer>
      )}
      <StyledContainer noBorderTop={noBorderTop}>
        <GridContainer
          repeat={3}
          width="100%"
          tabletWidth="80%"
          tabletMargin="auto"
          tabletRepeat={1}
        >
          {children}
        </GridContainer>
      </StyledContainer>
    </>
  );
}

export default StandardGridLayout;
