import {
  useRef,
  useEffect,
  useState,
  RefObject,
  useCallback,
  MutableRefObject,
  Dispatch,
  SetStateAction,
} from "react";
import Quill from "react-quill";
import { toast } from "react-toastify";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";

import { Maybe, SanityImageWithMeta, SanityPackaging } from "@graphql-types";
import { useStore } from "@state/store";
import {
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  LAPTOP_BREAKPOINT,
  SMALL_LAPTOP_BREAKPOINT,
} from "./constants";
import {
  arraysEquality,
  getCookie,
  getFileFromCache,
  isBrowser,
  mixAndMatchGroq,
  saveToCache,
  setCookie,
} from "./helper";
import { MailOptions, MixAndMatchParams, ScreenWidth, SelectOption } from "./types";
import { sanityClient, urlForImageRef } from "@lib/sanityClient";
import { AdCopy, AdCopyElements, AdElement, Customer, Order, Packaging } from "@state/types";
import { STATUS } from "react-joyride";

const defaultScreenWidth = {
  isTabletWidth: false,
  isMobileWidth: false,
  isLaptopWidth: false,
};

// get previous const for comparsion
export function usePrevious<T>(value: T) {
  const ref = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

// similar to use previous but will do an comparsion between prev & next prop
export function usePreviousCompare<T>(next: T, compare: (prev: T, next: T) => T) {
  const previousRef = useRef<T>(next);
  const previous = previousRef.current;

  const isEqual = compare(previous, next);

  useEffect(() => {
    if (previousRef.current == null) {
      return;
    }

    if (!isEqual && previousRef.current) {
      previousRef.current = next;
    }
  });

  return isEqual ? previous : next;
}

// check if component has been mounted
export function useHasMounted() {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, []);

  if (!mounted) {
    return false;
  }

  return mounted;
}

// the same as useState but will store the value in localStorage
function useStorage<T>(key: string, defaultValue: T | (() => T), storage: Storage) {
  const [value, setValue] = useState(() => {
    const jsonValue = storage.getItem(key);
    if (jsonValue != null) return JSON.parse(jsonValue);

    if (defaultValue instanceof Function) {
      return defaultValue();
    } else {
      return defaultValue;
    }
  });

  useEffect(() => {
    if (value === undefined) return storage.removeItem(key);
    storage.setItem(key, JSON.stringify(value));
  }, [key, value, storage]);

  const remove = useCallback(() => {
    setValue(undefined);
  }, []);

  return [value, setValue, remove];
}

export function useLocalStorage<T>(key: string, defaultValue: T | (() => T)) {
  if (!isBrowser()) return;
  return useStorage(key, defaultValue, window.localStorage);
}

export function useSessionStorage<T>(key: string, defaultValue: T | (() => T)) {
  if (!isBrowser()) return;
  return useStorage(key, defaultValue, window.sessionStorage);
}

// event listener hook
export function useEventListener(
  eventName: string,
  handler: (args: any) => void,
  elementToListen?: any,
) {
  if (!isBrowser()) return;
  const element = elementToListen ?? window;

  const savedHandler = useRef<typeof handler>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    //@ts-ignore
    const eventListener = (event: any) => savedHandler.current(event);
    element.addEventListener(eventName, eventListener);

    return () => element.removeEventListener(eventName, eventListener);
  }, [eventName, element]);
}

// set dark dark mode
export function useDarkMode(refObject?: RefObject<any>) {
  if (!isBrowser()) {
    return;
  }
  const [enabled, setEnabled] = useState(false);
  const element = refObject?.current ?? window.document.body;

  useEffect(() => {
    const className = "dark-mode";

    if (element) {
      if (enabled) {
        element.classList.add(className);
      } else {
        element.classList.remove(className);
      }
    }
  }, [enabled]);

  return [enabled, setEnabled];
}

// console logs the state when it gets updated
export function useUpdateLogger(value: any) {
  useEffect(() => {
    console.log(value);
  }, [value]);
}

// changes the boolean value to it's opposite value
export function useToggle(
  initialState = false,
): [boolean, () => void, Dispatch<SetStateAction<boolean>>] {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => setState(state => !state), []);

  return [state, toggle, setState];
}

// timeout hook, returns reset and clear function
export function useTimeout(callback: (args?: any) => void, delay: number) {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef<any>();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const set = useCallback(() => {
    timeoutRef.current = setTimeout(() => callbackRef.current(), delay);
  }, [delay]);

  const clear = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    set();
    return clear;
  }, [delay, set, clear]);

  const reset = useCallback(() => {
    clear();
    set();
  }, [clear, set]);

  useEffect(() => {
    return () => clear();
  }, []);

  return { reset, clear };
}

// debounce hook - run a callback after a certain delay
export function useDebounce(callback: (args?: any) => void, delay: number, dependencies: any[]) {
  const { reset, clear } = useTimeout(callback, delay);
  useEffect(reset, [...dependencies, reset]);
  useEffect(clear, []);
}

export function useArray(defaultValue: any[]) {
  const [array, setArray] = useState(defaultValue);

  const push = (element: any) => {
    setArray(a => [...a, element]);
  };

  const filter = (callback: (args?: any) => void) => {
    setArray(a => a.filter(callback));
  };

  const update = (index: number, newElement: any) => {
    setArray(a => [...a.slice(0, index), newElement, ...a.slice(index + 1, a.length)]);
  };

  const remove = (index: number) => {
    setArray(a => [...a.slice(0, index), ...a.slice(index + 1, a.length)]);
  };

  const clear = () => {
    setArray([]);
  };

  return { array, set: setArray, push, filter, update, remove, clear };
}

// counts the number of re-renders
export function useRenderCount() {
  const count = useRef(1);
  useEffect(() => {
    count.current++;
  }, []);
  return count.current;
}

// media query hook
export default function useMediaQuery(mediaQuery: string) {
  const [isMatch, setIsMatch] = useState(false);
  const [mediaQueryList, setMediaQueryList] = useState<MediaQueryList | null>(null);

  useEffect(() => {
    if (!isBrowser()) return;
    const list = window.matchMedia(mediaQuery);
    setMediaQueryList(list);
    setIsMatch(list.matches);
  }, [mediaQuery]);

  useEventListener("change", e => setIsMatch(e.matches), mediaQueryList);

  return isMatch;
}

// checks screen width
export function useCheckScreenWidth(): ScreenWidth {
  if (!isBrowser()) {
    return defaultScreenWidth;
  }
  const [screenWidth, setScreenWidth] = useState(defaultScreenWidth);
  const hasMounted = useHasMounted();

  const checkScreenWidth = () => {
    if (window.innerWidth <= MOBILE_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isLaptopWidth: true,
        isTabletWidth: true,
        isMobileWidth: true,
      });
      return;
    }
    if (window.innerWidth <= TABLET_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isLaptopWidth: true,
        isTabletWidth: true,
      });
      return;
    }
    if (window.innerWidth <= SMALL_LAPTOP_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isLaptopWidth: true,
      });
      return;
    }
    if (window.innerWidth > LAPTOP_BREAKPOINT) {
      setScreenWidth(defaultScreenWidth);
      return;
    }
  };

  useEventListener("resize", checkScreenWidth);

  useEffect(() => {
    checkScreenWidth();
  }, []);

  useEffect(() => {
    if (hasMounted) {
      checkScreenWidth();
    }
  }, [hasMounted]);

  return screenWidth;
}

export function useThrottle<T>(value: T, interval = 500): T {
  const [throttledValue, setThrottledValue] = useState<T>(value);
  const lastExecuted = useRef<number>(Date.now());

  useEffect(() => {
    if (Date.now() >= lastExecuted.current + interval) {
      lastExecuted.current = Date.now();
      setThrottledValue(value);
    } else {
      const timerId = setTimeout(() => {
        lastExecuted.current = Date.now();
        setThrottledValue(value);
      }, interval);

      return () => clearTimeout(timerId);
    }
    return;
  }, [value, interval]);

  return throttledValue;
}

//getting text elements as ref
export function useAdElements(
  selectedIndex: number,
  advertBoardRef: RefObject<HTMLDivElement>,
  elementsRef: MutableRefObject<HTMLDivElement[] | HTMLImageElement[]>,
  updating: boolean,
  zoom: number,
  savedElements?: AdElement[] | undefined,
): [AdElement[] | undefined, Dispatch<SetStateAction<AdElement[] | undefined>>] {
  const [elements, setElements] = useState<AdElement[] | undefined>(savedElements);
  const prevElement = usePrevious(elements);

  useEffect(() => {
    if (savedElements) {
      setElements(savedElements);
    }
  }, [savedElements]);

  useEffect(() => {
    if (selectedIndex === -1 || elements == null || advertBoardRef?.current == null) return;

    const elementDOMRect = elementsRef.current[selectedIndex].getBoundingClientRect();
    const adBoardDOMRect = advertBoardRef.current.getBoundingClientRect();
    const selectedElement = elements[selectedIndex];
    if (selectedElement == null) return;

    //map new elements with updated element (dimension & position)
    const updatedElements = elements.map(element => {
      if (element.id === selectedElement.id) {
        const { width, height } = elementDOMRect;
        const x = elementDOMRect.left - adBoardDOMRect.left;
        const y = elementDOMRect.top - adBoardDOMRect.top;
        return {
          ...element,
          value: element.value,
          dimension: { width, height },
          position: { x, y },
        };
      }
      return element;
    });
    setElements(updatedElements);
  }, [selectedIndex, updating, zoom]);

  useEffect(() => {
    if (elements == null) return;
    if (!elements.length) return;
    if (prevElement?.length !== elements.length) {
      const elementType = () => {
        if (elements[0].value.includes("base64")) return "image";
        if (elements[0].shape) return "shape";
        return "text";
      };
      // map elements with ids
      const elementsWithId = elements.map(element => {
        const r = Math.random().toString(10).substring(3);
        return { id: `${elementType()}-${r}`, ...element };
      });
      setElements(elementsWithId);
    }
  }, [elements]);

  return [elements, setElements];
}

export function useMixAndMatchParams() {
  const { order } = useStore();
  if (order == null) return null;
  const { product } = order;
  const [params, setParams] = useState<MixAndMatchParams>();

  useEffect(() => {
    if (product == null) return;
    const { advertSize, productSize } = product;

    setParams({
      countryCode: process.env.GATSBY_REGION,
      advertHeight: advertSize?.height,
      advertWidth: advertSize?.width,
      productHeight: productSize?.height,
      productWidth: productSize?.width,
    });
  }, []);

  return params;
}

// get product images with groq
export function useImagesFromSanity() {
  const { order } = useStore();
  const [imageObjects, setImageObjects] = useState<Partial<SanityImageWithMeta>[]>();
  const [imageUrls, setImageUrls] = useState<string[]>();
  const [calendarTitles, setCalendarTitles] = useState<string[]>();
  const mixAndMatchParams = useMixAndMatchParams();

  useEffect(() => {
    if (order?.product?.mixMatch) return;
    sanityClient
      .fetch(
        `*[_type=="product" && productId==$productId]{ "image": images[0], salesToolPreviewImage }`,
        {
          productId: order?.product?.productId,
        },
      )
      .then((result: any[]) => {
        if (!result.length) return;
        if (result[0] == null) return;
        const { image, salesToolPreviewImage } = result[0];

        const imageToSet = Boolean(salesToolPreviewImage?.asset) ? salesToolPreviewImage : image;
        setImageObjects([imageToSet]);
      });
  }, []);

  useEffect(() => {
    if (order?.product?.mixMatch == null || !order?.product?.mixMatch) return;
    if (mixAndMatchParams == null) return;
    const query = mixAndMatchGroq(
      `{ "image": images[0], salesToolPreviewImage, productId, title }`,
    );

    sanityClient.fetch(query, mixAndMatchParams).then((result: any[]) => {
      if (!result.length) return;
      if (result[0] == null) return;

      // first move the current order image to the beginning of the array
      const firstObject = result.find(obj => obj.productId === order?.product?.productId);
      const filteredArray = result.filter(p => p.productId !== order?.product?.productId);
      const reOrderedArray = [firstObject, ...filteredArray];

      // get first image from each product
      const firstImages = reOrderedArray.map(({ salesToolPreviewImage, image }) =>
        salesToolPreviewImage?.asset ? salesToolPreviewImage : image,
      );
      setImageObjects(firstImages);
      setCalendarTitles(reOrderedArray.map(prod => prod.title));
    });
  }, [mixAndMatchParams]);

  useEffect(() => {
    if (imageObjects && imageObjects.length > 0) {
      const images = imageObjects.map(imageObject =>
        urlForImageRef(imageObject?.asset as SanityImageSource).url(),
      );
      setImageUrls(images);
    }
  }, [imageObjects]);

  return [imageUrls, calendarTitles];
}

// loaded custom color input on mount for text editor
export function useCustomColorInputForQuill(quillRef: React.RefObject<Quill>) {
  if (!isBrowser()) return;
  const hasMounted = useHasMounted();

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const toolbar = editor.getModule("toolbar");

      function showColorPicker(value: string) {
        let picker = document.getElementById(value) as HTMLInputElement;
        picker = document.createElement("input");
        picker.id = value;
        picker.type = "color";
        picker.style.visibility = "hidden";
        picker.style.position = "absolute";
        picker.style.bottom = "0";
        picker.style.left = "30px";
        document.body.appendChild(picker);
        const contentLength = editor.getLength();
        picker.oninput = () => {
          if (value === "color-picker") {
            if (contentLength) {
              editor.formatText(0, contentLength, "color", picker?.value);
            } else {
              editor.format(value === "color-picker" ? "color" : "background", picker?.value);
            }
          } else {
            if (contentLength) {
              editor.formatText(0, contentLength, "background", picker?.value);
            } else {
              editor.format(value === "color-picker" ? "color" : "background", picker?.value);
            }
          }
        };
        picker.onchange = () => {
          if (value === "color-picker") {
            if (contentLength) {
              editor.formatText(0, contentLength, "color", picker?.value);
            } else {
              editor.format(value === "color-picker" ? "color" : "background", picker?.value);
            }
          } else {
            if (contentLength) {
              editor.formatText(0, contentLength, "background", picker?.value);
            } else {
              editor.format(value === "color-picker" ? "color" : "background", picker?.value);
            }
          }
        };
        picker.click();
      }

      toolbar.addHandler("color", showColorPicker);
      toolbar.addHandler("background", showColorPicker);
    }
  }, [hasMounted]);
}

export function useCustomFontInputForQuill(quillRef: React.RefObject<Quill>) {
  if (!isBrowser()) return;
  const hasMounted = useHasMounted();

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const toolbar = editor.getModule("toolbar");

      function fontChanged(value: string) {
        const contentLength = editor.getLength();
        if (contentLength) {
          editor.formatText(0, contentLength, "font", value);
        } else {
          editor.format("font", value);
        }
      }

      function fontSizeChanged(value: string) {
        const contentLength = editor.getLength();
        if (contentLength) {
          editor.formatText(0, contentLength, "size", value);
        } else {
          editor.format("size", value);
        }
      }

      toolbar.addHandler("font", fontChanged);
      toolbar.addHandler("size", fontSizeChanged);
    }
  }, [hasMounted]);
}

export function useDefaultFormatsForQuill(quillRef: React.RefObject<Quill>, quillValue: string) {
  if (!isBrowser()) return;
  const hasMounted = useHasMounted();

  useEffect(() => {
    if (!hasMounted) return;
    if (quillRef.current && quillValue === "") {
      const editor = quillRef.current?.getEditor();
      editor.format("size", "14px");
      editor.format("font", "arial");
    }
  }, [hasMounted, quillValue]);
}

export function useSyncAdCopyFromCache(
  orderId: string | undefined,
  productId?: Maybe<string> | undefined,
  setNotes?: Dispatch<SetStateAction<string>>,
  setSavedElements?: Dispatch<SetStateAction<AdCopyElements | undefined>>,
) {
  const { setAdCopy, loggedIn, setRehydrated, fetchAdCopy, adUpdating } = useStore();
  const [loading, setLoading] = useState(false);
  const prevAdUpdating = usePrevious(adUpdating);

  const fetchFromFirebase = async () => {
    const firebaseID = `${orderId}-${productId}`;
    console.log("no adcopy in local cache, getting firebase", firebaseID);
    const adCopy = await fetchAdCopy(firebaseID);
    if (adCopy && setSavedElements) {
      setSavedElements(adCopy.adCopyElements);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (loggedIn && orderId) {
      const saveCachedAdCopy = (response: AdCopy) => {
        setAdCopy(response);
        setSavedElements && setSavedElements(response.adCopyElements);
        setLoading(false);
        if (response?.notes && response?.notes !== "" && setNotes) setNotes(response?.notes);
        setRehydrated();
      };

      const checkForCachedCopy = async () => {
        console.log("getting from cache");
        setLoading(true);

        getFileFromCache(orderId)
          .then((response: AdCopy) => {
            if (response) {
              saveCachedAdCopy(response);
              console.log("restored adcopy from local cache", { response });
            } else {
              fetchFromFirebase();
            }
          })
          .catch(() => {
            console.log("CATCH: no adcopy in cache");
          });
      };

      checkForCachedCopy();
    }
  }, [loggedIn, orderId]);

  useEffect(() => {
    if (adUpdating) {
      toast.info("Ad copy still saving, please wait for updated changes");
      setLoading(true);
    }
    if (prevAdUpdating && !adUpdating) {
      fetchFromFirebase();
    }
  }, [adUpdating]);

  return {
    loading,
    setLoading,
  };
}

export function useSyncAdCopyToCache() {
  const { adCopy } = useStore();
  const prevCopy = usePrevious(adCopy?.copy);
  const prevSignature = usePrevious(adCopy?.signature);

  useEffect(() => {
    if (prevCopy !== adCopy?.copy || prevSignature !== adCopy?.signature) {
      saveToCache(adCopy, adCopy?.orderId);
    }
  }, [adCopy]);
}

export function useOnlineStatus(showOnlineStaus?: boolean) {
  const { setOnline, online } = useStore();

  // persist cache storage https://web.dev/persistent-storage/
  const checkMarkedAsPersistent = async () => {
    if (!isBrowser()) return;
    if (navigator.storage) {
      const isPersisted = await navigator.storage.persisted();
      console.log(`Persisted storage granted: ${isPersisted}`);
      if (isPersisted) return;
      await navigator.storage.persist();
    }
  };

  useEffect(() => {
    if (!isBrowser()) return;
    checkMarkedAsPersistent();
  }, []);

  useEffect(() => {
    if (!isBrowser()) return;
    if (navigator.onLine !== online) {
      setOnline(navigator.onLine);
      if (navigator.onLine) {
        if (showOnlineStaus) {
          toast.success("Connected to internet");
        }
      } else {
        toast.error("Disconnected from the internet");
      }
    }
  }, [navigator.onLine, online]);
}

export function usePackagingOptions(): [Maybe<SanityPackaging>[] | null | undefined, boolean] {
  const { order } = useStore();
  const [packaging, setPackaging] = useState<SanityPackaging[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const query = `*[_type=="product" && productId==$id]{packaging[]->{ _id, title, option, code }}`;

    const params = {
      id: order?.product?.productId,
    };
    setLoading(true);
    sanityClient
      .fetch(query, params)
      .then((result: any) => {
        setLoading(false);
        if (!result.length) return;
        if (result[0] == null) return;
        if (result[0].packaging == null || !result[0].packaging.length) return;

        const packagingOptions = result[0].packaging as SanityPackaging[];
        setPackaging(packagingOptions);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return [packaging, loading];
}

export function useMailerOptions(downloadUrl: string | undefined, to: string[]) {
  const { order } = useStore();
  if (order == null) return;
  const { repCodeRef, id, customerId, customerName, customerCompany, displayOrderID } = order;
  const [mailOptions, setMailOptions] = useState<MailOptions>();

  useEffect(() => {
    setMailOptions({
      from: `orders@easy2c.co.${process.env.GATSBY_REGION ?? "nz"}`,
      to: `${to.join()}`,
      subject: `Order Submission ${id}`,
      html: `<h1>Order info for ${customerName} - ${customerCompany}</h1>
      <p>Rep Code: ${repCodeRef}</p>
      <p>Order ID: ${displayOrderID ?? id}</p>
      <p>Customer ID: ${customerId}</p>
      <a href="${downloadUrl}">Asset download link</a>`,
    });
  }, [order, downloadUrl, to]);

  return mailOptions;
}

export function useNavigateOnLogout() {
  const { loggedIn } = useStore();
  const prevLoggdIn = usePrevious(loggedIn);
  const { pathname } = useLocation();

  useEffect(() => {
    if (prevLoggdIn && !loggedIn) {
      toast.success("Logout successful");
      setTimeout(() => navigate("/"), 1000);
    }

    if (!prevLoggdIn && !loggedIn && pathname.includes("sales-tool")) {
      toast.error("Please login");
      setTimeout(() => navigate("/"), 500);
    }
  }, [loggedIn]);
}

export function useUpdatePackagingOptions(
  addedOptions: Packaging[],
  setState: Dispatch<SetStateAction<Order>>,
) {
  const packagingToUpdate = addedOptions.filter(
    option => option?.price && option?.quantity && option?.title,
  );

  const prevAddedOptions = usePrevious(packagingToUpdate);

  useEffect(() => {
    if (prevAddedOptions.length && !addedOptions.length) {
      setState(state => ({
        ...state,
        packaging: [],
      }));
      return;
    }
    if (!addedOptions.length) return;
    if (addedOptions[0].price && addedOptions[0].quantity && addedOptions[0].title) {
      const arraysAreEqual = arraysEquality(prevAddedOptions, addedOptions);
      if (arraysAreEqual) return;

      setState(prevState => ({
        ...prevState,
        packaging: packagingToUpdate,
      }));
    }
  }, [addedOptions]);
}

export function useUpdateMixMatchToOrder(
  mixAndMatch: Partial<Order>[],
  setOrderState: Dispatch<SetStateAction<Order>>,
) {
  const prevMixAndMatch = usePrevious(mixAndMatch);

  useEffect(() => {
    if (prevMixAndMatch.length && !mixAndMatch.length) {
      setOrderState(prevState => ({
        ...prevState,
        mixAndMatch: [],
      }));
      return;
    }

    if (!mixAndMatch.length) return;
    if (mixAndMatch[0].price && mixAndMatch[0].quantity) {
      const arraysAreEqual = arraysEquality(prevMixAndMatch, mixAndMatch);
      if (arraysAreEqual) return;

      console.log(mixAndMatch);

      setOrderState(prevState => ({
        ...prevState,
        mixAndMatch,
      }));
    }
  }, [mixAndMatch]);
}

export function useMixAndMatchOptions(): [SelectOption[] | null | undefined, boolean] {
  const mixAndMatchParams = useMixAndMatchParams();
  const [options, setOptions] = useState<SelectOption[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (mixAndMatchParams == null) return;
    const query = mixAndMatchGroq(`{"id": productId, title}`);

    setLoading(true);
    sanityClient
      .fetch(query, mixAndMatchParams)
      .then((result: any[]) => {
        setLoading(false);
        if (!result.length) return;
        if (result[0] == null) return;
        setOptions(result);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [mixAndMatchParams]);

  return [options, loading];
}

export function useSeasonSelectOptions(orders: Order[] | undefined) {
  const [options, setOptions] = useState<SelectOption[]>();

  useEffect(() => {
    if (orders == null || options?.length) return;
    const seasons = orders.map(order => order?.season).filter(order => order?.length);
    const uniqueSeasons = [...new Set(seasons)];
    // sort seasons by year
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: "base" });
    const sortedSeasons = uniqueSeasons.sort(collator.compare);

    setOptions(sortedSeasons.map(season => ({ id: season, title: season })));
  }, [orders]);

  return options;
}

export function useSearchOrders(
  orders: Order[] | Customer[] | undefined,
  search: string,
): [Order[] | undefined, () => void] {
  const [searchResult, setsearchResult] = useState<Order[]>();
  const [foundIndices, setFoundIndices] = useState<number[]>([]);
  const [stringifiedOrders, setStringifiedOrders] = useState<{ object: string; index: number }[]>(
    [],
  );
  const prevSearch = usePrevious(search);

  const handleClearSearchResults = () => setsearchResult(undefined);

  useEffect(() => {
    if (orders == null) return;
    setStringifiedOrders(orders.map((order, index) => ({ object: JSON.stringify(order), index })));
  }, [orders]);

  useEffect(() => {
    if (prevSearch.length && !search.length) {
      handleClearSearchResults();
      return;
    }

    if (stringifiedOrders?.length && search.length) {
      const regExp = new RegExp(search.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1"), "gi");
      const indices = stringifiedOrders.reduce((acc, { object, index }) => {
        if (object.match(regExp)) {
          acc.push(index);
        }
        return acc;
      }, [] as number[]);

      setFoundIndices(indices);
    }
  }, [stringifiedOrders, search]);

  useEffect(() => {
    if (orders?.length) {
      const foundOrders = foundIndices.map(index => orders[index]);
      setsearchResult(foundOrders);
      return;
    }
  }, [foundIndices]);

  return [searchResult, handleClearSearchResults];
}

export function useSeasonFilter(
  orders: Order[] | undefined,
  optionFilter?: SelectOption | undefined,
): [Order[] | undefined, () => void] {
  const [filteredOrders, setFilteredOrders] = useState<Order[]>();

  // filter orders with season selected
  useEffect(() => {
    if (optionFilter == null) return;
    if (orders == null) return;
    const filtered = orders.filter(o => o.season === optionFilter.id);
    if (filtered) setFilteredOrders(filtered);
  }, [optionFilter]);

  const handleClearFilteredOrders = () => setFilteredOrders(undefined);

  return [filteredOrders, handleClearFilteredOrders];
}

export function useJoyrideHooks(cookie: string) {
  const [shouldRun, setShouldRun] = useState(false);
  const { showTips } = useStore();

  useEffect(() => {
    if (showTips) return setShouldRun(true);
    const cookieValue = getCookie(cookie);

    if (!cookieValue) {
      setShouldRun(true);
      return;
    }
  }, []);

  const handleDismiss = () => {
    setCookie(cookie, "true", 365);
  };

  const handleJoyrideCallback = (data: any) => {
    const { status } = data;

    if ([STATUS.FINISHED].includes(status)) {
      handleDismiss();
    }
  };

  return { shouldRun, handleDismiss, handleJoyrideCallback };
}
