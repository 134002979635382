import React from "react";
import styled from "styled-components";

import { BlocksContent, ModalBox } from "@global";
import { useStore } from "@state/store";
import { colors, colorsRGB, fontFamilies, fontWeights, MOBILE_BREAKPOINT } from "@util/constants";
import { Container } from "@util/standard";
import { Link } from "@global";
import { useStaticQuery, graphql } from "gatsby";

const StyledContainer = styled(Container)`
  text-align: center;
  margin: auto;
  flex-direction: column;
  width: 60%;
  margin: auto;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }
`;

const EnableButton = styled.button`
  background-color: transparent;
  border: none;
  text-decoration: underline;
  font-weight: ${fontWeights.bold};
  text-align: center;
  color: ${colors.navy};
  border: none;
  width: max-content;
  font-family: ${fontFamilies.gotham};
  margin: 0 auto;
  cursor: pointer;
  opacity: 1;
  white-space: initial;

  &:hover {
    opacity: 0.8;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }
`;

const DisableButton = styled(Link)`
  background-color: ${colors.navy};
  color: ${colors.white};
  padding: 25px 40px;
  border-radius: 40px;
  width: max-content;
  margin: 0 auto 30px auto;
  cursor: pointer;
  opacity: 1;
  border: none;
  white-space: initial;
  &:hover {
    opacity: 0.8;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 90%;
    padding: 5%;
  }
`;

function AgeRestrictionPopup() {
  const { disableAgeRestriction, ageRestrictionPopupVisible, closeAgeRestrictionPopup } =
    useStore();

  const { sanityAgeRestrictionPopup } = useStaticQuery(graphql`
    {
      sanityAgeRestrictionPopup {
        mainText {
          ...sanityBlockContent
        }
        enableText
        disableText
      }
    }
  `);

  const handleClick = () => {
    disableAgeRestriction();
    closeAgeRestrictionPopup();
  };

  const { mainText, disableText, enableText } = sanityAgeRestrictionPopup;

  return (
    <ModalBox
      visible={ageRestrictionPopupVisible}
      setVisible={disableAgeRestriction}
      hideCloseIcon
      customStyle={{
        content: {
          top: "50%",
          left: "50%",
          width: "70%",
          height: "60%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          border: "none",
          overflow: "visible",
          background: "white",
        },
        overlay: {
          backgroundColor: `${colorsRGB.black(0.9)}`,
          zIndex: 100,
        },
      }}
    >
      <Container width="100%" tabletMargin="auto">
        <StyledContainer>
          {mainText ? (
            <BlocksContent data={mainText} />
          ) : (
            <h2>You are about to see adult content. You must be aged 18 or older.</h2>
          )}

          <DisableButton linkText={disableText} internalLink={{ slug: { current: "" } }} />
          <EnableButton onClick={handleClick}>{enableText}</EnableButton>
        </StyledContainer>
      </Container>
    </ModalBox>
  );
}

export default AgeRestrictionPopup;
