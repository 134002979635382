import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { FaPalette } from "react-icons/fa";
import { AiFillLock } from "react-icons/ai";

import { Maybe, Query, SanityProduct } from "@graphql-types";
import { Container } from "@util/standard";
import BlocksContent from "../blocksContent";
import { assets, colors } from "@util/constants";
import { Color } from "@util/types";
import { Link } from "@global";

interface Props {
  mixMatch?: Maybe<boolean>;
  fixedProduct?: Maybe<boolean>;
  flexiProduct?: Maybe<boolean>;
  product?: SanityProduct;
}

const TextWrapper = styled(Container)<{ color?: Color }>`
  ${({ color }) => color && `color: ${colors[color]}`};
  img {
    height: max-content;
    margin-top: 5px;
    margin-right: 10px;
  }
  h3 {
    margin: 0px;
  }
  a {
    ${({ color }) => color && `color: ${colors[color]}`};
  }
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

function MixMatchPromotional({ fixedProduct, flexiProduct, mixMatch, product }: Props) {
  const { sanityMixMatchText, allSanityPromotionalText, sanityFixedText, sanityFlexiText }: Query =
    useStaticQuery(graphql`
      query {
        sanityMixMatchText {
          title
          textBlock {
            ...sanityBlockContent
          }
        }
        allSanityPromotionalText {
          nodes {
            title
            region {
              countryCode
            }
            textBlock {
              ...sanityBlockContent
            }
          }
        }
        sanityFlexiText {
          title
          textBlock {
            ...sanityBlockContent
          }
        }
        sanityFixedText {
          title
          textBlock {
            ...sanityBlockContent
          }
        }
      }
    `);

  const isMixMatch = mixMatch && sanityMixMatchText && sanityMixMatchText.textBlock;
  const isFixed = fixedProduct && sanityFixedText && sanityFixedText.textBlock;
  const isFlexi = flexiProduct && sanityFlexiText && sanityFlexiText.textBlock;

  const regionalPromotionText = allSanityPromotionalText.nodes.filter(data => {
    return data.region?.countryCode === process.env.GATSBY_REGION;
  });

  const productCategory = product && product.categories && product?.categories[0];
  const productCollection = productCategory?.collectionRef;

  const productParams = `collection=${productCollection?.title}&category=${productCategory?.title}&collectionSlug=${productCollection?.slug?.current}`;
  const sizeParams = `&productId=${product?.productId}&advertHeight=${product?.advertSize?.height}&advertWidth=${product?.advertSize?.width}&productHeight=${product?.productSize?.height}&productWidth=${product?.productSize?.width}`;

  return (
    <>
      {isMixMatch && (
        <TextWrapper margin="40px 0 0 0" flexDirection="column" color="blue">
          {sanityMixMatchText?.title && (
            <TextWrapper>
              <img src={assets.mixAndMatch} />
              <div>
                <h3>{sanityMixMatchText?.title}</h3>
                <BlocksContent data={sanityMixMatchText?.textBlock} />
                <StyledLink
                  linkText={"View more mix & match options"}
                  internalLink={{
                    slug: {
                      current: `mix-and-match?${productParams}${sizeParams}`,
                    },
                  }}
                />
              </div>
            </TextWrapper>
          )}
        </TextWrapper>
      )}
      {isFixed && (
        <TextWrapper margin="40px 0 0 0" flexDirection="column" color="blue">
          {sanityFixedText?.title && (
            <TextWrapper>
              <AiFillLock style={{ fontSize: 55, height: "max-content", margin: "5px 10px 0 0" }} />

              <div>
                <h3>{sanityFixedText?.title}</h3>
                <BlocksContent data={sanityFixedText?.textBlock} />
              </div>
            </TextWrapper>
          )}
        </TextWrapper>
      )}
      {isFlexi && (
        <TextWrapper margin="40px 0 0 0" flexDirection="column" color="blue">
          {sanityFlexiText?.title && (
            <TextWrapper>
              <FaPalette style={{ fontSize: 60, height: "max-content", margin: "5px 10px 0 0" }} />

              <div>
                <h3>{sanityFlexiText?.title}</h3>
                <BlocksContent data={sanityFlexiText?.textBlock} />
              </div>
            </TextWrapper>
          )}
        </TextWrapper>
      )}
      {regionalPromotionText && regionalPromotionText[0].textBlock && (
        <TextWrapper margin="40px 0 0 0" flexDirection="column" color="green">
          {regionalPromotionText[0].title && (
            <TextWrapper>
              <img src={assets.promotionalProduct} />

              <div>
                <h3>{regionalPromotionText[0].title}</h3>
                <BlocksContent data={regionalPromotionText[0].textBlock} />
              </div>
            </TextWrapper>
          )}
        </TextWrapper>
      )}
    </>
  );
}

export default MixMatchPromotional;
