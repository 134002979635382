import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";

import { BlocksContent } from "@global";
import { SanityTestimonialsSlider } from "@graphql-types";
import { colorsRGB, TABLET_BREAKPOINT } from "@util/constants";
import { Container, P } from "@util/standard";
import { useCheckScreenWidth } from "@util/hooks";
import { Autoplay } from "swiper";

interface Props {
  data: SanityTestimonialsSlider;
}

const TestimonialsWrapper = styled(Container)`
  width: 70%;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  }
`;

function TestimonialsSlider({ data }: Props) {
  if (data == null) return null;

  const { header, testimonials } = data;

  const { isMobileWidth } = useCheckScreenWidth();

  return (
    <Container backgroundRGBColor={colorsRGB.lightBlue(0.4)} width="100%">
      <Container width="100%" margin="120px 0" tabletMargin="80px 0" tabletFlexDirection="column">
        <Container
          width="14%"
          tabletWidth="50%"
          flexDirection="column"
          margin="50px 0 0 0"
          tabletMargin="0 0 40px 4%"
          padding="0 8%"
          mobilePadding="0"
        >
          <h2>{header}</h2>
        </Container>
        {testimonials && (
          <TestimonialsWrapper>
            <Swiper
              slidesPerView={isMobileWidth ? 1.2 : 2.5}
              spaceBetween={15}
              freeMode={true}
              pagination={{
                clickable: true,
              }}
              loop={true}
              className="mySwiper"
              autoplay={{
                delay: 8000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
            >
              {testimonials.map((testimonial, index) => {
                if (testimonial == null) return null;

                const defineColor =
                  index % 3 === 0 ? "lightBlue" : (index + 1) % 2 === 0 ? "cream" : "mint";

                return (
                  <SwiperSlide key={testimonial._id}>
                    <Container
                      height="max-content"
                      minWidth="263px"
                      backgroundColor={defineColor}
                      margin={index === 0 ? "0 0 0 4%" : ""}
                      borderRadius={10}
                    >
                      <Container
                        width="100%"
                        margin="80px 60px"
                        tabletMargin="60px 30px"
                        flexDirection="column"
                      >
                        <BlocksContent
                          data={testimonial.statement}
                          hasReadMoreButton
                          readMoreLimitLimit={342}
                        />
                        <P fontWeight="bold">{testimonial.attestant}</P>
                      </Container>
                    </Container>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </TestimonialsWrapper>
        )}
      </Container>
    </Container>
  );
}

export default TestimonialsSlider;
