import React from "react";
import styled from "styled-components";

import { BlocksContent, Button } from "@global";
import { SanityCta } from "@graphql-types";
import { Container } from "@util/standard";
import { Color } from "@util/types";
import { TABLET_BREAKPOINT } from "@util/constants";

interface Props {
  data: SanityCta;
  backgroundColor?: Color;
  roundCorner?: boolean;
  padding?: string;
  mobilePadding?: string;
}

const CtaWrapper = styled(Container)<{
  roundCorner: boolean;
  padding?: string;
  mobilePadding?: string;
}>`
  width: 100%;
  flex-direction: column;
  padding: 140px 0;
  ${({ padding }) => padding && `padding: ${padding};`};
  ${({ roundCorner }) => roundCorner && `border-radius: 50px 0 0 0`};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 90px 0;
    ${({ mobilePadding }) => mobilePadding && `padding: ${mobilePadding};`};
  }
`;

const TextWrapper = styled(Container)`
  text-align: center;
  flex-direction: column;
  width: 60%;
  margin: auto;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
  }
`;

function Cta({ data, backgroundColor, roundCorner, padding, mobilePadding }: Props) {
  if (data == null) {
    return null;
  }

  return (
    <CtaWrapper
      backgroundColor={backgroundColor}
      padding={padding}
      mobilePadding={mobilePadding}
      roundCorner={roundCorner as boolean}
    >
      <TextWrapper>
        <BlocksContent data={data.ctaText} />
      </TextWrapper>
      <Container margin="auto">
        {data.ctaLinks?.map((link, index) => {
          if (link == null) {
            return null;
          }

          return (
            <Button
              {...link}
              theme={index === 0 ? "base" : "text"}
              linkText={link.linkText ?? "Find out more"}
              isProductEnquiryBtn={link.isProductEnquiryBtn}
              key={link._key}
            />
          );
        })}
      </Container>
    </CtaWrapper>
  );
}

export default Cta;
