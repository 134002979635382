import { useStaticQuery, graphql } from "gatsby";

import { Maybe, Query } from "@graphql-types";

const AllProductQuery = (collectionName?: Maybe<string> | undefined) => {
  const { allSanityProduct }: Query = useStaticQuery(graphql`
    query ProductQuery {
      allSanityProduct {
        nodes {
          _id
          _key
          _type
          slug {
            current
          }
          title
          categories {
            title
            collectionRef {
              title
            }
          }
          images {
            ...sanityFullWidthImage
          }
          regions {
            region {
              countryCode
            }
          }
        }
      }
    }
  `);

  if (collectionName) {
    const sortedProducts = allSanityProduct.nodes.filter(product => {
      return product?.categories && product?.categories[0]?.collectionRef?.title === collectionName;
    });

    return sortedProducts;
  }

  return allSanityProduct.nodes;
};

export default AllProductQuery;
