import create, { SetState, GetState, Mutate, StoreApi } from "zustand";
import { persist, devtools } from "zustand/middleware";
import firebase from "gatsby-plugin-firebase";

import {  SelectedCalendar, TemplateFieldset } from "@util/types";
import { User, Order, Customer, AdCopyElements, AdCopy, Product, SelectedTemplate, TemplateFormData } from "./types";
import { Maybe, SanitySizeBlock } from "@graphql-types";
import { toast } from "react-toastify";
import { handleFirebaseError, saveBase64ToStorage } from "@util/helper";

interface State {
  rehydrated: boolean;
  drawerVisible: boolean;
  categoryFilters: string[];
  currentSearchValue: string;
  ageRestrictionActive: boolean;
  ageRestrictionPopupVisible: boolean;
  drawerForm: string | "enquiry" | "login";
  selectedCalendar: SelectedCalendar;
  user?: User;
  order?: Order;
  selectedCustomer?: Customer;
  online: boolean;
  createCustomerVisible: boolean;
  loggedIn: boolean;
  adCopy?: AdCopy;
  showTips?: boolean;
  // B2C
  product?: Product;
  template?: SelectedTemplate;
  adUpdating: boolean;

  setLoggedIn: (loggedIn: boolean) => void;
  setSelectedCustomer: (selectedCustomer: Customer | undefined) => void;
  openDrawer: () => void;
  closeDrawer: () => void;
  disableAgeRestriction: () => void;
  openAgeRestrictionPopup: () => void;
  closeAgeRestrictionPopup: () => void;
  openLoginDrawer: () => void;
  setUser: (user: User) => void;
  setOrder: (order: Order | undefined) => void;
  setProduct: (product: Product) => void;
  setAdCopy: (adCopy: AdCopy | undefined) => void;
  setAdCopySignature: (signature: string) => void;
  getAdCopyElements: () => AdCopyElements | undefined;
  getProductAvertSize: () => Maybe<SanitySizeBlock> | undefined;
  clearSession: () => void;
  setRehydrated: () => void;
  setOnline: (online: boolean) => void;
  setCreateCustomerVisible: (visible: boolean) => void;
  getOrderZipName: () => string | undefined;
  setShowTips: (showTips: boolean) => void;
  setTemplate: (template: SelectedTemplate) => void;

  //Template hooks
  usingTemplate: boolean;
  templateAvailable: {
    returned: boolean;
    available: boolean;
    templates: any;
    pickerSelected: boolean;
  };
  templateFieldset: TemplateFieldset[] | null;
  setUsingTemplate: (usingTemplate: boolean) => void;
  setTemplateAvailable: (templateAvailable: any) => void;
  setTemplateFieldset: (templateFieldset: TemplateFieldset[]) => void;
  fetchAdCopy: (
    firebaseID: Maybe<string> | undefined,
  ) => Promise<firebase.firestore.DocumentData | undefined>;
  saveAdCopy: (data: AdCopy, calendarID: Maybe<string> | undefined) => Promise<void>;
}

const defaultSelectedCalendar = {
  productId: "",
  productName: "",
};

const defaultState = {
  rehydrated: false,
  categoryFilters: [],
  currentSearchValue: "",
  ageRestrictionActive: true,
  ageRestrictionPopupVisible: false,
  drawerVisible: false,
  selectedCalendar: defaultSelectedCalendar,
  online: false,
  createCustomerVisible: false,
  loggedIn: false,
  usingTemplate: false,
  templateAvailable: {
    returned: false,
    available: false,
    templates: null,
    pickerSelected: false,
  },
  templateFieldset: null,
  adUpdating: false,
};

const store = persist<
  State,
  SetState<State>,
  GetState<State>,
  Mutate<StoreApi<State>, [["zustand/persist", Partial<State>]]>
>(
  (set, get) => ({
    ...defaultState,
    drawerForm: "enquiry",
    setTemplate: template => set({ template }),
    openLoginDrawer: () => set({ drawerVisible: true, drawerForm: "login" }),
    openDrawer: () => set({ drawerVisible: true, drawerForm: "enquiry" }),
    closeDrawer: () => set({ drawerVisible: false, drawerForm: "enquiry" }),
    disableAgeRestriction: () => set({ ageRestrictionActive: false }),
    openAgeRestrictionPopup: () => set({ ageRestrictionPopupVisible: true }),
    closeAgeRestrictionPopup: () => set({ ageRestrictionPopupVisible: false }),
    getAdCopyElements: () => get().adCopy?.adCopyElements,
    getProductAvertSize: () => get().order?.product?.advertSize,
    setAdCopy: adCopy => set({ adCopy }),
    setAdCopySignature: signature => set({ adCopy: { ...get().adCopy, signature } }),
    clearSession: () =>
      set({
        user: undefined,
        selectedCustomer: undefined,
        order: undefined,
        loggedIn: false,
        templateAvailable: {
          returned: false,
          available: false,
          templates: null,
          pickerSelected: false,
        },
        templateFieldset: null,
      }),
    setRehydrated: () => set({ rehydrated: true }),
    setOnline: online => set({ online }),
    setCreateCustomerVisible: createCustomerVisible => set({ createCustomerVisible }),
    setSelectedCustomer: selectedCustomer => set({ selectedCustomer }),
    setUser: user => set({ user, loggedIn: true }),
    setOrder: order => set({ order }),
    setLoggedIn: loggedIn => set({ loggedIn }),
    getOrderZipName: () => get().order?.displayOrderID ?? get().order?.id,
    setUsingTemplate: (usingTemplate) => set({ usingTemplate }),
    setTemplateAvailable: (templateAvailable) => set({ templateAvailable }),
    setTemplateFieldset: (templateFieldset) => set({ templateFieldset }),
    setShowTips: (showTips) => set({ showTips }),
    setProduct: product => set({ product }),
    fetchAdCopy: async firebaseID => {
      if (!firebaseID) return;
      const firestore = firebase.firestore();
      const adcopyRef = firestore.collection("adCopies").doc(firebaseID);
      const template = await adcopyRef.get();
      const data = template.data();
      set({ adCopy: data });
      return data;
    },
    saveAdCopy: async (data, calendarID) => {
      if (!calendarID || !data) return;
      set({ adUpdating: true });
      const firestore = firebase.firestore();
      const templateRef = firestore.collection("adCopies").doc(calendarID);
      const folderPath = `image-assets/${calendarID}/`;
      const { adCopyElements, copy } = data;
  
      try {
        toast.info("Saving images to cloud - this may take some time, please do not close this window...⏳");
        // Process image elements asynchronously and wait for all promises to resolve
        const imageElements = await Promise.all(
          adCopyElements?.imageElements?.map(async imageElement => {
            const { value, id } = imageElement;
            const storagePath = `${folderPath}${id}.png`;
            const imageValue = await saveBase64ToStorage(value, storagePath);
            toast.info(`Image ${id} saved to the cloud, please continue to wait...⏳`);
            return { ...imageElement, value: imageValue || value };
          }) || [],
        );
  
        toast.info("Saving ad copy to the cloud, almost there....⌛️");
        const copyUrl = await saveBase64ToStorage(copy, `${folderPath}ad-copy.png`);
        const backgroundUrl = await saveBase64ToStorage(adCopyElements?.backgroundUrl, `${folderPath}background.png`);
  
        // Prepare the data to save
        const dataToSave = {
          ...data,
          copy: copyUrl,
          adCopyElements: {
            ...adCopyElements,
            ...(backgroundUrl && { backgroundUrl: backgroundUrl }),
            imageElements,
          },
        } as AdCopy;
  
        // Save the data to Firestore
        await templateRef.set(dataToSave);
  
        set({ adCopy: dataToSave, adUpdating: false });
        toast.success("Ad Copy saved to the cloud 🙌");
      } catch (error) {
        set({ adUpdating: false });
        handleFirebaseError(error, "Error saving ad copy, file size of image may be too large");
      }
    }
  }),
  {
    name: "easy2c-store", // unique name
    getStorage: () => localStorage,
    // omit calendar from persisted storage
    partialize: state =>
      Object.fromEntries(
        Object.entries(state).filter(
          ([key]) =>
            !["adCopy", "rehydrated", "usingTemplate", "templateAvailable", "template"].includes(
              key,
            ),
        ),
      ),
  },
);

export const useStore = create(
  process.env.GATSBY_NODE_ENV !== "production" ? devtools(store) : store,
);
