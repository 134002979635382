import React from "react";

import { BlocksContent, Image, Parallax } from "@global";
import {
  Maybe,
  SanityBlockContent,
  SanityCollection,
  SanityImageWithMeta,
  SanityPackaging,
} from "@graphql-types";
import { Container } from "@util/standard";

import { formatInternalLink } from "@util/helper";
import { ColumnWrapper, ParallaxWrapper, StyledLink, TextWrapper } from "./global.styles";
import { validateType } from "@util/validateType";

interface Props {
  data: Maybe<SanityCollection | SanityPackaging | SanityBlockContent>[];
}

interface ColumnProps {
  index: number;
  image?: Maybe<SanityImageWithMeta> | undefined;
  description: Maybe<SanityBlockContent> | undefined;
  collection?: SanityCollection;
  isLast?: boolean;
  key: Maybe<string> | undefined;
  scrollValue?: number;
  padding?: string;
  backgroundColor?: Maybe<string> | undefined;
}

const ParallaxColumn = ({
  index,
  image,
  description,
  key,
  collection,
  scrollValue,
  padding,
  backgroundColor,
}: ColumnProps) => {
  if (!description) return null;
  const defineColor = index % 3 === 0 ? "mint" : (index + 1) % 2 === 0 ? "cream" : "lightBlue";
  const styles = {
    "--container-color": backgroundColor || "transparent",
  } as React.CSSProperties;

  return (
    <Parallax speed={scrollValue}>
      <ColumnWrapper
        index={index}
        backgroundColor={!backgroundColor ? defineColor : undefined}
        key={key}
        padding={padding}
        style={styles}
      >
        {image && (
          <Container width="100%" height="130px">
            <Image data={image} />
          </Container>
        )}
        <TextWrapper justifyContent="center">
          {description && <BlocksContent data={description} />}
        </TextWrapper>
        {collection && (
          <StyledLink to={`${formatInternalLink(collection)}${collection?.slug?.current}`}>
            View {collection.title}
          </StyledLink>
        )}
      </ColumnWrapper>
    </Parallax>
  );
};

function ParallaxColumns({ data }: Props) {
  if (!data) return null;

  return (
    <ParallaxWrapper>
      {data.map((item, index) => {
        if (!item) return null;
        const scrollValue = index % 2 ? -1 : 1;

        if (validateType.isCollection(item)) {
          const { collectionIcon, collectionDescription, _key } = item;
          return (
            <ParallaxColumn
              index={index}
              key={_key}
              image={collectionIcon}
              description={collectionDescription}
              collection={item}
              scrollValue={scrollValue}
            />
          );
        }
        if (validateType.isPackaging(item)) {
          const { packagingIcon, packagingDescription, _key } = item;
          return (
            <ParallaxColumn
              index={index}
              key={_key}
              image={packagingIcon}
              description={packagingDescription}
              scrollValue={scrollValue}
            />
          );
        }
        if (validateType.isBlockContent(item)) {
          return (
            <ParallaxColumn
              index={index}
              key={item._key}
              description={item}
              scrollValue={scrollValue}
            />
          );
        }
        return null;
      })}
    </ParallaxWrapper>
  );
}

export default ParallaxColumns;
