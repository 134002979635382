const pages = {
  home: "/",
  about: "/about",
  contact: "/contact",
  products: "/products/",
  collections: "/collections/",
  careers: "/careers",
  resources: "/resources",
  faqs: "/faqs",
  testimonials: "/testimonials",
  promotionalProducts: "/promotional-products",
  policies: "/policies",
};

module.exports = Object.freeze(pages);
