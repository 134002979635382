import React from "react";
import styled from "styled-components";

import { BlocksContent, Image } from "@global";
import { SanityInformationColumns } from "@graphql-types";
import { Container } from "@util/standard";
import { colorsRGB, fontWeights, TABLET_BREAKPOINT } from "@util/constants";

interface Props {
  data: SanityInformationColumns;
}

const Wrapper = styled(Container)`
  border-bottom: 1px solid ${colorsRGB.navy(0.4)};
  width: 98%;
  margin: auto;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    width: 82%;
  }
`;

const HeaderWrapper = styled(Container)`
  width: 29%;
  border-right: 1px solid ${colorsRGB.navy(0.4)};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    border: none;
    width: 100%;
  }
`;

const Header = styled.h4`
  width: 55%;
  font-weight: ${fontWeights.bold};
  margin: 120px auto 0 auto;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 50%;
    margin: 70px 0 0 0;
  }
`;

const TextWrapper = styled(Container)`
  p,
  h4,
  h3,
  h2 {
    margin-top: 0px;
  }
`;

function InformationColumns({ data }: Props) {
  if (data == null) return null;

  const { header, leftColumn, rightColumn, image } = data;
  return (
    <Wrapper>
      <HeaderWrapper>
        <Header>{header}</Header>
      </HeaderWrapper>
      <Container
        padding="120px 11%"
        mobilePadding="16px 0 70px 0"
        flexDirection="column"
        width="71%"
        tabletWidth="100%"
      >
        <Container width="100%" justifyContent="space-between" tabletFlexDirection="column">
          {leftColumn && (
            <TextWrapper width="46%" margin="0 5% 0 0" tabletWidth="100%">
              <BlocksContent data={leftColumn} />
            </TextWrapper>
          )}
          {rightColumn && (
            <TextWrapper width="48%" tabletWidth="100%">
              <BlocksContent data={rightColumn} />
            </TextWrapper>
          )}
        </Container>
        {image && (
          <Container
            width="100%"
            height="100%"
            margin="60px 0 0 0"
            tabletMargin="35px 0 0 0"
            tabletHeight="300px"
          >
            <Image data={image} objectFit="cover" />
          </Container>
        )}
      </Container>
    </Wrapper>
  );
}

export default InformationColumns;
