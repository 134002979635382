import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { graphql, Link, useStaticQuery } from "gatsby";

import {
  Maybe,
  Query,
  SanityCollection,
  SanityProduct,
  SanityProductConnection,
  SanitySlug,
} from "@graphql-types";
import { assets, colorsRGB, TABLET_BREAKPOINT } from "@util/constants";
import pagesPaths from "@util/pagesPaths";
import { Container, DesktopContainer, MobileContainer, P } from "@util/standard";

interface Props {
  productData: SanityProductConnection;
  slug: Maybe<SanitySlug> | undefined;
  collection?: Maybe<SanityCollection> | undefined;
}

const StyledLink = styled(Link)<{ noMargin?: boolean }>`
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    margin-right: 2%;

    ${({ noMargin }) => noMargin && `margin: 0;`};
  }
`;

const NavButtonWrapper = styled(Container)`
  width: 69px;
  height: 44px;
  margin-bottom: 10px;
  img {
    margin: auto;
  }
  p {
    margin: auto;
  }

  border-radius: 0px 4px 4px 0px;
  cursor: pointer;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    border-radius: 4px;
    margin-bottom: 40px;
  }
`;

function ProductNavArrows({ slug, collection, productData }: Props) {
  if (productData == null || slug == null) return null;

  const [currentProductIndex, setCurrentProductIndex] = useState(0);

  const findCurrentProduct = () => {
    productData.edges.map((product, index) => {
      if (product == null || product.node == null) return null;
      if (product.node.slug?.current === slug.current) {
        setCurrentProductIndex(index);
        return;
      }
      return;
    });
  };

  useEffect(() => {
    findCurrentProduct();
  });

  const last = productData.edges[productData.edges.length - 1].node;
  const first = productData.edges[0].node;

  const prev = productData.edges[currentProductIndex].previous ?? last;
  const next = productData.edges[currentProductIndex].next ?? first;

  return (
    <>
      <DesktopContainer position="absolute" left="0px">
        <Container flexDirection="column">
          {/* {prev && (
            <StyledLink to={`${pagesPaths.products}${prev.slug?.current}`}>
              <NavButtonWrapper backgroundRGBColor={colorsRGB.navy(0.1)}>
                <img src={assets.arrowLeft} />
              </NavButtonWrapper>
            </StyledLink>
          )}
          {next && (
            <Link to={`${pagesPaths.products}${next.slug?.current}`}>
              <NavButtonWrapper backgroundRGBColor={colorsRGB.navy(0.1)}>
                <img src={assets.arrowRight} />
              </NavButtonWrapper>
            </Link>
          )} */}
          {collection && (
            <StyledLink to={`${pagesPaths.collections}${collection.slug?.current}` as string}>
              <NavButtonWrapper backgroundColor="navy">
                <P color="white">All</P>
              </NavButtonWrapper>
            </StyledLink>
          )}
        </Container>
      </DesktopContainer>
      <MobileContainer>
        <Container width="100%" justifyContent="space-between">
          {/* {prev && (
            <StyledLink to={`${pagesPaths.products}${prev.slug?.current}`}>
              <NavButtonWrapper backgroundRGBColor={colorsRGB.navy(0.1)}>
                <img src={assets.arrowLeft} />
              </NavButtonWrapper>
            </StyledLink>
          )} */}
          {collection && (
            <StyledLink to={`${pagesPaths.collections}${collection.slug?.current}` as string}>
              <NavButtonWrapper backgroundColor="navy">
                <P color="white">All</P>
              </NavButtonWrapper>
            </StyledLink>
          )}
          {/* {next && (
            <StyledLink to={`${pagesPaths.products}${next.slug?.current}`} noMargin>
              <NavButtonWrapper backgroundRGBColor={colorsRGB.navy(0.1)}>
                <img src={assets.arrowRight} />
              </NavButtonWrapper>
            </StyledLink>
          )} */}
        </Container>
      </MobileContainer>
    </>
  );
}

export default ProductNavArrows;
