import React, { useEffect } from "react";
import styled from "styled-components";

import { SanityCollection, SanityProduct } from "@graphql-types";
import { P } from "@util/standard";
import { Link } from "gatsby";
import pages from "@util/pagesPaths";
import { fontWeights, colorsRGB, colors, TABLET_BREAKPOINT } from "@util/constants";
import { CheckBox } from "@global";
import { useStore } from "@state/store";
import { checkCategoryProductLength } from "@util/helper";

interface Props {
  collection: SanityCollection;
  context?: string;
  hideTitle?: boolean;
  products?: SanityProduct[];
}

const CollectionLink = styled(Link)<{ selected: boolean }>`
  font-weight: ${fontWeights.normal};
  padding: 22px 0;
  border-bottom: 1px solid ${colorsRGB.navy(0.4)};

  ${({ selected }) =>
    selected &&
    `
  font-weight: ${fontWeights.bold};
  padding: 22px 0 0 0;
  border-bottom: none;
  `};

  &:visited {
    color: ${colors.navy};
  }
`;

const CategoriesWrapper = styled.div`
  padding: 40px 0;
  border-bottom: 1px solid ${colorsRGB.navy(0.4)};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    border: none;
  }
`;

function CategorySelector({ collection, context, hideTitle, products }: Props) {
  const urlMatches = context === collection.slug?.current;
  const { categoryFilters } = useStore();

  const updateCategoryList = (category: string) => {
    if (categoryFilters.includes(category)) {
      const newFilters = categoryFilters.filter(filter => filter !== category);
      useStore.setState({ categoryFilters: newFilters });
      return;
    }
    const newFilters = categoryFilters.concat(category);
    useStore.setState({ categoryFilters: newFilters });
  };

  useEffect(() => {
    useStore.setState({ categoryFilters: [] });
  }, []);

  return (
    <>
      {!hideTitle && (
        <CollectionLink
          to={`${pages.collections}${collection?.slug?.current}`}
          selected={urlMatches}
        >
          {collection.title}
        </CollectionLink>
      )}

      {urlMatches && collection?.collectionCategories && (
        <CategoriesWrapper>
          <P margin="0" fontWeight="medium">
            Filter by type
          </P>
          {collection?.collectionCategories.map((category, index) => {
            if (category == null || category.title == null) return null;
            const { title } = category;
            const categoryProducts = checkCategoryProductLength(title, products, collection.title);

            if (categoryProducts && categoryProducts.length === 0) return null;

            return (
              <CheckBox
                label={title}
                onChange={() => updateCategoryList(title)}
                key={title + index}
              />
            );
          })}
        </CategoriesWrapper>
      )}
    </>
  );
}

export default CategorySelector;
