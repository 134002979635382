import React, { useState } from "react";
import styled from "styled-components";

import { Container, P, AbsoluteContainer } from "@util/standard";
import { Maybe, SanityImage, SanityImageWithMeta, SanityProduct } from "@graphql-types";
import { Image } from "@global";
import { assets, colors, colorsRGB, TABLET_BREAKPOINT } from "@util/constants";
import { Link } from "gatsby";
import { useStore } from "@state/store";
import { urlForImageRef } from "@lib/sanityClient";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";

const pages = require("../../util/pagesPaths");

interface Props {
  data: SanityProduct;
  hasBorderLeft?: boolean;
  hasBorderBottom?: boolean;
  disableMobileBorderBottom?: boolean;
  sanityClientImages?: boolean;
}

const ProductCardWrapper = styled.div<{
  borderLeft?: boolean;
  borderBottom?: boolean;
  disableMobileBorderBottom?: boolean;
}>`
  ${({ borderLeft }) => borderLeft && ` border-left: 1px solid ${colorsRGB.navy(0.4)};`};
  ${({ borderBottom }) => borderBottom && ` border-bottom: 1px solid ${colorsRGB.navy(0.4)};`};
  padding: 40px 6%;
  z-index: 1;
  a {
    color: ${colors.navy};
  }
  a:visited {
    color: ${colors.navy};
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    border-left: none;
    border-right: none;
    border-bottom: 1px solid ${colorsRGB.navy(0.4)};
    ${({ disableMobileBorderBottom }) => disableMobileBorderBottom && `border-bottom: none;`};
    padding: 30px 0px 35px 0px;
  }
`;

const ImageWrapper = styled(Container)<{ ageRestricted?: boolean | null | undefined }>`
  position: relative;

  height: 302px;

  width: 100%;
  .gatsby-image-wrapper {
    border-radius: 4px;
    ${({ ageRestricted }) => ageRestricted && `filter: blur(10px);`};
  }
`;

const StyledAbsoluteContainer = styled(AbsoluteContainer)`
  bottom: 0px;
  width: 100%;
  background-color: ${colorsRGB.white(0.5)};
  z-index: 5;
  cursor: pointer;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

const ProductName = styled.h4`
  margin: 0px;
`;

function ProductCard({
  data,
  hasBorderLeft,
  hasBorderBottom,
  disableMobileBorderBottom,
  sanityClientImages,
}: Props) {
  const [hideButton, setHideButton] = useState(true);
  const { openDrawer, ageRestrictionActive } = useStore();

  if (data == null) {
    return null;
  }

  const handleClick = e => {
    e.preventDefault();
    openDrawer();
  };

  return (
    <ProductCardWrapper
      borderLeft={hasBorderLeft}
      borderBottom={hasBorderBottom}
      disableMobileBorderBottom={disableMobileBorderBottom}
      onMouseEnter={() => setHideButton(false)}
      onMouseLeave={() => setHideButton(true)}
    >
      <Link to={`${pages.products}${data.slug?.current}`}>
        <ImageWrapper ageRestricted={ageRestrictionActive && data.adultContent}>
          {!sanityClientImages && data.images && data.images[0] && data.images[0].asset ? (
            <Image isBackground data={data.images[0]} />
          ) : sanityClientImages && data.images && data.images[0] && data.images[0].asset ? (
            <img
              src={urlForImageRef(data.images[0].asset as SanityImageSource).url()}
              style={{ width: "100%", objectFit: "cover" }}
            />
          ) : (
            <img src={assets.productDefaultImage} style={{ width: "100%", objectFit: "contain" }} />
          )}
          {!hideButton && (
            <StyledAbsoluteContainer onClick={e => handleClick(e)}>
              <P margin="16px auto" textAlign="center" fontWeight="medium">
                Enquire about this product
              </P>
            </StyledAbsoluteContainer>
          )}
        </ImageWrapper>
        <div>
          {data.categories && data.categories[0] && data.categories[0].collectionRef && (
            <P margin="16px 0 4px 0" tabletMargin="12px 0 8px 0">
              {data.categories[0].collectionRef.title}
            </P>
          )}
          <ProductName>{data.title}</ProductName>
        </div>
      </Link>
    </ProductCardWrapper>
  );
}

export default ProductCard;
