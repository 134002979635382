import {
  SanityCollection,
  SanityColumnContent,
  SanityFeaturedCollections,
  SanityImage,
  SanityImageLink,
  SanityImageMenu,
  SanityInformationColumns,
  SanityLink,
  SanityNestedLink,
  SanityProduct,
  SanityRange,
  SanityVideo,
  SanityThemeSelector,
  SanityTestimonialsSlider,
  SanityListBlock,
  SanityHome,
  SanityAboutPage,
  SanityCareersPage,
  SanityContactPage,
  SanityFaqsPage,
  SanityPoliciesPage,
  SanityPromotionalProducts,
  SanityResourcesPage,
  SanityTestimonialsPage,
  SanityPackaging,
  SanityHero,
  SanityImageWithMeta,
  SanityContainer,
  SanityBlockContent,
  SanityCards,
  SanityCard,
  SanityForm
} from "@graphql-types";

export function assertType<T>(type: string) {
  return (data: any): data is T => data?._type === type;
}

export const validateType = {
  isHero: assertType<SanityHero>("hero"),
  isImage: assertType<SanityImage>("image"),
  isImageWithMeta: assertType<SanityImageWithMeta>("imageWithMeta"),
  isColumnContent: assertType<SanityColumnContent>("columnContent"),
  isFeaturedCollections: assertType<SanityFeaturedCollections>("featuredCollections"),
  isImageLink: assertType<SanityImageLink>("imageLink"),
  isImageMenu: assertType<SanityImageMenu>("imageMenu"),
  isInformationColumns: assertType<SanityInformationColumns>("informationColumns"),
  isLink: assertType<SanityLink>("link"),
  isNestedLink: assertType<SanityNestedLink>("nestedLink"),
  isProduct: assertType<SanityProduct>("product"),
  isRange: assertType<SanityRange>("range"),
  isVideo: assertType<SanityVideo>("video"),
  isThemeSelector: assertType<SanityThemeSelector>("themeSelector"),
  isTestimonialsSlider: assertType<SanityTestimonialsSlider>("testimonialsSlider"),
  isListBlock: assertType<SanityListBlock>("listBlock"),
  isCollection: assertType<SanityCollection>("collection"),
  isPackaging: assertType<SanityPackaging>("packaging"),
  isHome: assertType<SanityHome>("home"),
  isAboutPage: assertType<SanityAboutPage>("aboutPage"),
  isCareersPage: assertType<SanityCareersPage>("careersPage"),
  isContactPage: assertType<SanityContactPage>("contactPage"),
  isFaqsPage: assertType<SanityFaqsPage>("faqsPage"),
  isPoliciesPage: assertType<SanityPoliciesPage>("policiesPage"),
  isPromotionalProducts: assertType<SanityPromotionalProducts>("promotionalProducts"),
  isResourcesPage: assertType<SanityResourcesPage>("resourcesPage"),
  isTestimonialsPage: assertType<SanityTestimonialsPage>("testimonialsPage"),
  isContainerBlock: assertType<SanityContainer>("container"),
  isBlockContent: assertType<SanityBlockContent>("blockContent"),
  isCards: assertType<SanityCards>("cards"),
  isCard: assertType<SanityCard>("card"),
  isForm: assertType<SanityForm>("form"),
};