import React, { ReactNode, useState } from "react";
import { Swiper } from "swiper/react";
import SwiperCore, { Pagination, Controller, Navigation } from "swiper";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// update swiper styling here
const StyledSwiper = styled(Swiper)`
  .swiper-pagination {
  }
  .swiper-pagination-bullet-active {
  }
  .swiper-pagination-bullet-active,
  .swiper-pagination-bullet {
  }

  width: 100%;
`;

interface Props {
  children: ReactNode;
}

export default function Carousel({ children }: Props) {
  const [controller, setController] = useState<SwiperCore>();

  // elements such as dots or next/prev are added through the modules property
  return (
    <StyledSwiper
      modules={[Controller, Pagination, Navigation]}
      onSwiper={setController}
      slidesPerView={8}
      spaceBetween={10}
      pagination={{
        clickable: true,
      }}
      onTransitionStart={swiper => console.log(swiper.realIndex)}
    >
      {/* in order for the children to show you must wrap every child with "SwiperSlide" component */}
      {children}
    </StyledSwiper>
  );
}
