import React from "react";

import { SanityCtaBlock } from "@graphql-types";
import { Container } from "@util/standard";
import { Cta } from "@global";

interface Props {
  data: SanityCtaBlock;
}

function CtaBlock({ data }: Props) {
  if (data == null) {
    return null;
  }

  return (
    <Container tabletFlexDirection="column">
      {data.ctas?.map((cta, index) => {
        if (cta == null) {
          return null;
        }
        return (
          <Container width="50%" tabletWidth="100%" key={cta._key}>
            <Cta
              data={cta}
              backgroundColor={index > 0 ? "cream" : "transparent"}
              roundCorner={index > 0}
            />
          </Container>
        );
      })}
    </Container>
  );
}

export default CtaBlock;
