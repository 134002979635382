import React, { createElement, useState } from "react";
import PortableText from "@sanity/block-content-to-react";
import styled from "styled-components";

import { Maybe, SanityBlockContent } from "@graphql-types";
import { Container, P } from "@util/standard";
import { urlForImageRef } from "@lib/sanityClient";
import { Link } from "@global";

const Img = styled.img`
  height: 100%;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  margin: 25px 0;
`;

interface Props {
  data: Maybe<SanityBlockContent> | undefined;
  hasReadMoreButton?: boolean;
  readMoreLimitLimit?: number;
}

const BlocksContent = ({ data, hasReadMoreButton, readMoreLimitLimit }: Props) => {
  if (data == null) {
    return null;
  }

  const { _rawBlockContent } = data;

  if (_rawBlockContent == null) {
    return null;
  }

  const block = (props: any) => {
    const [readMoreOpen, setReadMoreOpen] = useState(false);

    const { style = "normal" } = props.node;
    const tag = style;
    if (/^h\d/.test(tag)) {
      return createElement(tag, {}, props.children);
    }

    if (hasReadMoreButton) {
      const textLengthLimit = readMoreLimitLimit ?? 342;
      const textExceedsLimit = props.children[0].length > textLengthLimit;
      const limitedString = textExceedsLimit
        ? props.children[0].substring(0, textLengthLimit) + "..."
        : props.children[0];

      return (
        <div>
          <P>{readMoreOpen ? props.children[0] : limitedString}</P>
          {textExceedsLimit && (
            <P
              cursor="pointer"
              fontWeight="medium"
              onClick={() => setReadMoreOpen(!readMoreOpen)}
              underlined
            >
              {readMoreOpen ? "Read less" : "Read more"}
            </P>
          )}
        </div>
      );
    }
    return <P>{props.children}</P>;
  };

  const ImageRenderer = (props: any) => {
    return (
      <Container>
        <Img src={urlForImageRef(props.node.asset).url() as string} />
      </Container>
    );
  };

  const BlockLink = (props: any) => {
    return (
      <Link
        underline
        color="navy"
        linkText={props.node.linkText}
        externalLink={props.node.externalLink}
        internalLink={props.node.internalLink}
      />
    );
  };

  const heading = ({ node, ...rest }: any) => {
    if (!node) return null;
    const { headingSize, headingTag, headingText } = node;
    const headingAttr = {
      headingSize,
      headingTag,
      headingText,
    };
    return <Heading {...headingAttr} />;
  }

  return (
    <PortableText
      blocks={_rawBlockContent}
      serializers={{
        types: { block, imageWithMeta: ImageRenderer, link: BlockLink, heading },
      }}
      imageOptions={{ auto: "format", fit: "fill" }}
      projectId={process.env.SANITY_PROJECT_ID}
      dataset={process.env.GATSBY_NODE_ENV ?? "development"}
    />
  );
};

export default BlocksContent;

function Heading({
  headingSize = "h3",
  headingTag = "h3",
  headingText,
  children,
  data,
  classNames,
  attr,
}: any) {
  const Tag = (data?.headingTag ?? headingTag);
  const customClassName = data?.headingSize ?? headingSize ?? "";

  const headingClassName = `heading-${customClassName}`;
  const makeClassnames = classNames ? `${classNames} ${headingClassName}` : `${headingClassName}`;
  return (
    <Tag className={makeClassnames} {...attr}>
      {data?.headingText ?? headingText ?? children}
    </Tag>
  );
}
