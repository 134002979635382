import { useSaveTemplate } from "./firebaseHooks";
import { sanityClient } from "@lib/sanityClient";
import { useStore } from "@state/store";
import { Maybe } from "@graphql-types";
import { isBrowser } from "./helper";

export const useTemplateHooks = () => {
  if (!isBrowser()) return {
    fetchTemplates: () => {},
    fetchFieldset: () => {},
    fetchAll: () => {},
  };

  const { getCalendarTemplates, getCalendarFieldsets } = useSaveTemplate();
  const {
    adCopy,
    order,
    templateAvailable,
    setTemplateAvailable,
    setTemplateFieldset,
  } = useStore();

  const fetchTemplates = async (gridCode?: Maybe<string>) => {
    // if (templateAvailable.returned) return;
    let calendarID = gridCode || order?.product?.gridCode;

    if (!calendarID) {
      const query =
        await sanityClient.fetch(`*[_type == "product" && productId == "${adCopy?.calendarId}"][0]{
            gridCode
          }`);
      console.log({ query });

      calendarID = query.gridCode;
    }

    if (calendarID) {
      const templatesList = await getCalendarTemplates(calendarID);
      console.log({ templateAvailable });

      if (templatesList?.length === 0) {
        setTemplateAvailable({
          ...useStore.getState().templateAvailable,
          available: false,
          returned: true,
          templates: null,
        });
        // toast.error("No templates found for this ad copy");
        return;
      }

      setTemplateAvailable({
        ...useStore.getState().templateAvailable,
        returned: true,
        available: true,
        templates: templatesList,
      });
    }
  };

  const fetchFieldset = async (gridCode?: Maybe<string>) => {
    let calendarID = gridCode || order?.product?.gridCode;
    console.log({ calendarID });
    if (calendarID == null) {
      const query =
        await sanityClient.fetch(`*[_type == "product" && productId == "${adCopy?.calendarId}"][0]{
            gridCode
          }`);
      calendarID = query.gridCode;
    }

    if (calendarID) {
      const fieldset = await getCalendarFieldsets(calendarID);
      console.log({ fieldset });
      if (fieldset) {
        setTemplateFieldset(fieldset[0]?.fields);
      }
    }
  };

  const fetchAll = async (gridCode?: Maybe<string>) => {
    if (!isBrowser()) return;
    await fetchTemplates(gridCode);
    await fetchFieldset(gridCode);
  };

  return {
    fetchTemplates,
    fetchFieldset,
    fetchAll,
  };
};
