import React from "react";
import styled from "styled-components";

import { Image, Cta, Button } from "@global";
import { SanityImageCtaBlock } from "@graphql-types";
import { Container } from "@util/standard";
import { TABLET_BREAKPOINT } from "@util/constants";

interface Props {
  data: SanityImageCtaBlock;
}

const CtaWrapper = styled.div`
  width: 75%;
  z-index: 2;
  margin: 0 auto;
  padding: 140px 0;
  button {
    margin-top: 26px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    padding: 90px 0;
    h2,
    h3,
    h4 {
      width: 87%;
      margin: auto !important;
    }
  }
`;

const StyledButton = styled(Button)`
  display: block;
`;

function imageCtaBlock({ data }: Props) {
  if (data == null || data.cta == null) {
    return null;
  }
  const { resourceLink, backgroundImage, cta } = data;

  return (
    <>
      <Container position="relative" width="100%" flexDirection="column">
        {cta && (
          <CtaWrapper>
            <Cta data={cta} padding="0" mobilePadding="0" />
            {resourceLink && (
              <StyledButton
                theme="base"
                margin="0 auto"
                linkText={resourceLink.linkText}
                externalLink={resourceLink.resource?.resourceLink}
              />
            )}
          </CtaWrapper>
        )}
        {backgroundImage && <Image isBackground data={backgroundImage} />}
      </Container>
    </>
  );
}

export default imageCtaBlock;
