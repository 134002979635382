import React from "react";

import { Container, P } from "@util/standard";

function SuccessToastMessage() {
  return (
    <Container flexDirection="column" margin="40px">
      <P fontWeight="bold">
        You are now being directed off of the Easy2c website to a third party site
      </P>
      <P>We have opened a new tab</P>
    </Container>
  );
}

export default SuccessToastMessage;
