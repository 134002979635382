import React, { ReactNode } from "react";
import { Link as GatsbyLink } from "gatsby";
import styled from "styled-components";

import { A } from "@util/standard";
import { Color } from "@util/types";
import { Maybe, Scalars } from "@graphql-types";
import { formatInternalLink } from "@util/helper";
import { colors } from "@util/constants";
import { useStore } from "@state/store";

interface Props {
  linkText?: Maybe<Scalars["String"]>;
  externalLink?: Maybe<Scalars["String"]>;
  internalLink?: Maybe<any>;
  className?: string;
  color?: Color;
  hoverColor?: Color;
  padding?: string;
  opacity?: number;
  margin?: string;
  underline?: boolean;
  children?: ReactNode;
  isProductEnquiryBtn?: Maybe<boolean> | undefined;
}

const StyledGatsbyLink = styled(GatsbyLink)<{
  padding?: string;
  opacity?: number;
  margin: string | undefined;
  color?: Color;
  underline?: boolean;
}>`
  display: block;
  user-select: none;
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ padding }) => padding && `padding: ${padding}`};
  ${({ color }) => color && `color: ${colors[color]};`};
  ${({ margin }) => margin && `margin: ${margin};`};
  ${({ underline }) => underline && `text-decoration : underline;`};

  a:visited {
    ${({ color }) => color && `color: ${colors[color]};`};
  }
`;

const Link = ({
  linkText,
  internalLink,
  externalLink,
  className = "",
  color,
  children,
  hoverColor,
  padding,
  opacity,
  margin,
  underline,
  isProductEnquiryBtn,
}: Props) => {
  const { openDrawer } = useStore();

  if (isProductEnquiryBtn) {
    return (
      <StyledGatsbyLink
        activeClassName="active"
        className={className}
        onClick={openDrawer}
        padding={padding}
        opacity={opacity}
        margin={margin}
        color={color}
        to={""}
        onAuxClick={openDrawer}
        underline={underline}
      >
        {children ? children : linkText}
      </StyledGatsbyLink>
    );
  }

  if (internalLink) {
    const to = () => {
      if (internalLink?.slug) return formatInternalLink(internalLink) + internalLink.slug.current;

      return formatInternalLink(internalLink);
    };

    return (
      <StyledGatsbyLink
        activeClassName="active"
        className={className}
        to={to()}
        padding={padding}
        opacity={opacity}
        margin={margin}
        color={color}
        underline={underline}
      >
        {children ? children : linkText}
      </StyledGatsbyLink>
    );
  }

  if (externalLink) {
    return (
      <A
        href={externalLink ?? "/"}
        className={className}
        target="_blank"
        rel="noreferrer"
        color={color}
        opacity={opacity}
        hoverColor={hoverColor}
        margin={margin}
        underlined={underline}
      >
        {children ? children : linkText}
      </A>
    );
  }

  return null;
};

export default Link;
