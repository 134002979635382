import React, { RefObject, useEffect, useRef, useState } from "react";

import { useAllProductQuery } from "@api";
import { BlocksContent, Image, ProductCard } from "@global";
import { SanityCategory, SanityThemeSelector } from "@graphql-types";
import { Container, GridContainer, P } from "@util/standard";
import { assets } from "@util/constants";
import {
  CategoryContainer,
  CategoryImageWrapper,
  Icon,
  ScrollContainer,
  StandardLayout,
} from "./global.styles";
import { filterProducts, isBrowser } from "@util/helper";

interface Props {
  data: SanityThemeSelector;
}

function ThemeSelector({ data }: Props) {
  if (data == null) return null;

  const { themes } = data;

  const regionalisedThemes =
    themes &&
    themes.find(theme => {
      if (theme == null) return null;
      return theme.region?.countryCode === process.env.GATSBY_REGION;
    });

  if (regionalisedThemes == null) return null;

  const { textContent, categories } = regionalisedThemes;

  const [currentCategory, setCurrentCategory] = useState<SanityCategory>();

  useEffect(() => {
    if (categories && categories[0]) {
      setCurrentCategory(categories[0]);
    }
  }, []);

  const allProducts = useAllProductQuery(currentCategory?.collectionRef?.title ?? undefined);

  const filteredProducts =
    currentCategory &&
    currentCategory.title &&
    filterProducts(allProducts, [currentCategory.title]);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleMobileScrollToItem = (category: SanityCategory, ref: RefObject<HTMLDivElement>) => {
    setCurrentCategory(category);
    if (isBrowser()) {
      const windowHalf = window.innerWidth / 2;
      if (containerRef.current && ref.current) {
        containerRef.current.scrollTo({
          left: ref.current.offsetLeft - windowHalf + ref.current.offsetWidth / 2,
          behavior: "smooth",
        });
      }
    }
    return;
  };

  return (
    <StandardLayout
      flexDirection="column"
      tabletWidth="92%"
      mobileWidth="100%"
      margin="150px auto"
      tabletMargin="70px auto"
    >
      {textContent && (
        <Container width="50%" tabletWidth="100%" mobileWidth="80%" tabletMargin="auto">
          <BlocksContent data={textContent} />
        </Container>
      )}
      {categories && (
        <>
          <ScrollContainer ref={containerRef} width="100%" mobileOverFlowX="scroll">
            {categories.map((category, index) => {
              if (category == null) return null;
              const categoryRef = useRef<HTMLDivElement>(null);

              const categorySelected = category._id === currentCategory?._id;
              const categoryHasImage = Boolean(
                category.categoryImage && category.categoryImage.asset,
              );

              return (
                <CategoryContainer
                  ref={categoryRef}
                  isFirst={index === 0}
                  isLast={index === categories.length - 1}
                  onClick={() => handleMobileScrollToItem(category, categoryRef)}
                  key={category._id}
                >
                  <CategoryImageWrapper selected={categorySelected} hasImage={categoryHasImage}>
                    {category.categoryImage && (
                      <Image objectFit="fill" data={category.categoryImage} />
                    )}
                  </CategoryImageWrapper>
                  <P
                    textAlign="center"
                    width="100%"
                    fontWeight={categorySelected ? "bold" : "medium"}
                  >
                    {category.title}
                  </P>
                </CategoryContainer>
              );
            })}
          </ScrollContainer>
          <Icon src={assets.arrowDown} />

          {currentCategory && filteredProducts && (
            <GridContainer
              width="100%"
              repeat={3}
              tabletColumnGap={20}
              mobileWidth="80%"
              margin="auto"
            >
              {filteredProducts.map((product, index) => {
                if (product == null || index > 11) return null;
                return <ProductCard data={product} key={product._id} disableMobileBorderBottom />;
              })}
            </GridContainer>
          )}
        </>
      )}
    </StandardLayout>
  );
}

export default ThemeSelector;
