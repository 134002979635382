import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";

import { SanityAttributeBlock, SanityProduct, SanityProductConnection } from "@graphql-types";
import { Container, P } from "@util/standard";
import ProductGallery from "./productGallery";
import { BlocksContent, Button, AttributesBlock } from "@global";
import { appPaths, colorsRGB } from "@util/constants";
import { useStore } from "@state/store";
import MixMatchPromotional from "./mixMatchPromotional";
import ProductNavArrows from "./productNavArrows";
import { Product, Order } from "@state/types";
import { getRegionPricing, isBrowser } from "@util/helper";
import { useUpdateOrderToFirestore } from "@util/firebaseHooks";
import { useTemplateHooks } from "@util/templateHooks";

interface Props {
  data: SanityProduct;
  navButtonProducts: SanityProductConnection;
}

const Title = styled.h1`
  margin: 20px 0;
`;

const StyledContainer = styled.div`
  border-bottom: 1px solid ${colorsRGB.navy(0.6)};
  padding-bottom: 40px;

  h3 {
    margin-bottom: 10px;
  }
`;

const Info = ({ data, product }: { data: SanityProduct; product: Product }) => {
  if (!isBrowser()) return null;
  const { user, order, openDrawer } = useStore();
  const { attributes, fixedProduct, flexiProduct, mixMatch, regions } = data;
  const { productId, productName, advertSize, productSize, gridCode } = product;
  // TODO: add pricing logic

  const [orderState, _setOrderState] = useState<Partial<Order>>({
    product: {
      mixMatch,
      advertSize,
      productSize,
      productId,
      productName,
      gridCode,
    },
  });

  const pricing = getRegionPricing(regions);
  const updateCallback = () => navigate(appPaths.editor);

  const [loading, handleUpdateOrder] = useUpdateOrderToFirestore(
    order?.id,
    orderState,
    updateCallback,
  );

  const handleGoToEditor = () => {
    if (order == null) {
      navigate(appPaths.createOrder);
      return;
    }

    handleUpdateOrder();
  };

  return (
    <div>
      <StyledContainer>
        {attributes && <AttributesBlock data={attributes as SanityAttributeBlock[]} />}
        {!user && (
          <Button margin="30px 0 0px 0" linkText="Enquire about product" onClick={openDrawer} />
        )}
      </StyledContainer>

      {!user && (
        <MixMatchPromotional
          fixedProduct={fixedProduct}
          flexiProduct={flexiProduct}
          mixMatch={mixMatch}
          product={data}
        />
      )}
      {user && (
        <Container flexDirection="column">
          {pricing?.price && (
            <Container alignItems="center">
              <h2 style={{ marginRight: "10px" }}>{pricing.price}</h2>
              <p>per unit (excl GST)</p>
            </Container>
          )}
          <Button
            loading={loading}
            margin="20px 0 0 0"
            onClick={handleGoToEditor}
            theme="base"
            linkText="Edit calendar ad as Admin"
          />
        </Container>
      )}
    </div>
  );
};

function ProductHero({ data, navButtonProducts }: Props) {
  const { selectedCalendar, setProduct } = useStore();
  const templateStore = useStore(state => state.templateAvailable);
  const { fetchAll } = useTemplateHooks();

  useEffect(() => {
    if (!templateStore.returned) {
      fetchAll(data.gridCode);
    }
  }, [templateStore.returned, data?.productId]);

  if (!data) return null;
  const { advertSize, productSize, calendarId, title, gridCode, prices } = data;
  const product = { advertSize, productSize, productId: calendarId, productName: title, gridCode };

  const handleGoToDashboard = () => {
    setProduct(product);
    navigate(appPaths.dashboard);
  };

  //Set the product in store
  useEffect(() => {
    if (selectedCalendar.productId != data.productId) {
      const product = {
        productName: data.title,
        productId: data.productId,
        gridCode: gridCode,
      };
      useStore.setState({ selectedCalendar: product });
    }
  }, []);

  return (
    <Container
      width="80%"
      tabletWidth="92%"
      tabletFlexDirection="column"
      margin="0 auto 140px auto"
      padding="100px 0 0 0"
      mobilePadding="27px 0 0 0"
      tabletMargin="0 auto 45px auto"
      maxWidth="1220px"
    >
      <ProductNavArrows
        slug={data.slug}
        collection={data.categories && data.categories[0] && data.categories[0].collectionRef}
        productData={navButtonProducts}
      />
      <Container margin="0 60px 0 0" width="51%" tabletWidth="100%">
        <ProductGallery data={data} />
      </Container>
      <Container
        flexDirection="column"
        width="43%"
        tabletWidth="90%"
        tabletMargin="62px auto 0 auto"
      >
        {data?.categories?.length && data?.categories[0]?.collectionRef && (
          <P margin="0">{data.categories[0]?.collectionRef?.title}</P>
        )}
        <Title>{data.title}</Title>
        <StyledContainer>
          <BlocksContent data={data.productDescription} />
          {advertSize && productSize && (
            <Container flexDirection="column">
              {productSize && productSize.width && productSize.height && (
                <P margin="0 0 5px 0">
                  Calendar size - {productSize.width}mm X {productSize.height}mm
                </P>
              )}
              {advertSize.width && advertSize.height && (
                <P margin="0">
                  Advert size - {advertSize.width}mm X {advertSize.height}mm
                </P>
              )}
            </Container>
          )}
          {Boolean(prices?.length) && (
            <Container flexDirection="column">
              <h3>Price</h3>
              {prices?.map((price, index) => {
                if (!price) return null;
                return (
                  <Container
                    key={index}
                    alignItems="center"
                    justifyContent="space-between"
                    width="200px"
                  >
                    <span>{price.quantityRange}</span>
                    <span>
                      <b>${price.eachPrice}</b> each
                    </span>
                  </Container>
                );
              })}
            </Container>
          )}

          {/* {templateStore.templates?.length > 0 && (
            <Button
              width="100%"
              margin="40px 0 0 0"
              theme="base"
              linkText="Personalise your calendar"
              onClick={handleGoToDashboard}
            />
          )} */}
        </StyledContainer>
        <Info data={data} product={product} />
      </Container>
    </Container>
  );
}

export default ProductHero;
