import React, { Dispatch, ReactNode, SetStateAction } from "react";
import styled from "styled-components";

import { Container, DesktopContainer, MobileContainer } from "@util/standard";
import { colorsRGB, assets } from "@util/constants";
import { MobileFilterList } from "@global";

interface Props {
  children: ReactNode;
  filterResultChildren: ReactNode;
  mobileVisibility: boolean;
  padding?: string;
  setMobileVisibility: Dispatch<SetStateAction<boolean>>;
}

const Title = styled.h4`
  margin: 0;
`;

const Icon = styled.img`
  margin-right: 12px;
`;

const MobileTitleWrapper = styled(Container)`
  border-bottom: 1px solid ${colorsRGB.navy(0.4)};
  padding-bottom: 30px;
`;

function ResourceFilterBar({
  children,
  filterResultChildren,
  mobileVisibility,
  setMobileVisibility,
  padding,
}: Props) {
  return (
    <Container width="100%" tabletFlexDirection="column" margin="0 0 40px 0">
      <Container width="30%" tabletWidth="100%">
        <DesktopContainer width="100%">
          <Container
            width="50%"
            flexDirection="column"
            padding={padding ? padding : "150px 25% 0 25%"}
          >
            {children}
          </Container>
        </DesktopContainer>

        <MobileContainer width="100%">
          <MobileTitleWrapper width="80%" margin="35px auto 5px auto">
            <Icon src={assets.filterIcon} />
            <Title onClick={() => setMobileVisibility(true)}>Filter</Title>
          </MobileTitleWrapper>
          <MobileFilterList
            visible={mobileVisibility}
            onClose={() => setMobileVisibility(false)}
            title="Categories"
          >
            {children}
          </MobileFilterList>
        </MobileContainer>
      </Container>
      <Container margin="0 2% 0 0" flexDirection="column" width="70%" tabletWidth="100%">
        {filterResultChildren}
      </Container>
    </Container>
  );
}

export default ResourceFilterBar;
