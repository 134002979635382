import React from "react";
import styled from "styled-components";

import { Maybe, SanityHero } from "@graphql-types";
import { AbsoluteContainer, Container } from "@util/standard";
import { colors, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { BlocksContent, Button } from "@global";
import { Color } from "@util/types";

interface Props {
  title?: Maybe<string> | undefined;
  data: Maybe<SanityHero> | undefined;
}

const Header = styled.h1`
  margin: 20vh 0 0 16%;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 22vh 0 30vh 9%;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 90%;
    margin: auto;
  }
`;

const TextWrapper = styled(Container)<{ textColor?: string | null | undefined }>`
  margin: 18vh 0 0 16%;
  ${({ textColor }) =>
    textColor === "mint" ? `color: ${colors.green};` : `color: ${colors.navy};`};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 30vh 0 0 9%;
    h1,
    h2,
    h3,
    h4 {
      margin: 0;
    }
  }
`;

const CtaWrapper = styled(AbsoluteContainer)`
  bottom: 0px;
  right: 0px;
  background-color: ${colors.white};
  width: 35%;

  flex-direction: column;
  border-radius: 50px 0px 0px 0px;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 78%;
    border-radius: 50px 50px 0px 0px;
    left: 50%;
    transform: translateX(-50%);
    right: unset;
  }
`;

const CtaText = styled(Container)`
  flex-direction: column;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    h3,
    h4 {
      font-size: 14px;
    }
  }
`;

function Hero({ data, title }: Props) {
  if (data == null) return null;

  const { blockContent, backgroundColor, cta } = data;
  return (
    <Container
      backgroundColor={backgroundColor ? (backgroundColor as Color) : "lightBlue"}
      width="100%"
      position="relative"
      height="40vh"
      tabletHeight="60vh"
      minHeight="200px"
    >
      {title ? (
        <Header color={backgroundColor === "mint" ? "green" : "navy"}>{title}</Header>
      ) : (
        <TextWrapper textColor={backgroundColor}>
          <BlocksContent data={blockContent} />
        </TextWrapper>
      )}
      {cta && (
        <CtaWrapper>
          <CtaText flexDirection="column" padding="8% 15% 13% 15%" mobilePadding="5% 16% 10% 16%">
            <BlocksContent data={cta.ctaText} />
            <Container>
              {cta.ctaLinks &&
                cta.ctaLinks.map(link => {
                  if (link == null || link.linkText == null) return null;
                  return <Button {...link} theme="bordered" key={link._key} />;
                })}
            </Container>
          </CtaText>
        </CtaWrapper>
      )}
    </Container>
  );
}

export default Hero;
