import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";

import { Color, SelectOption } from "@util/types";
import { colors } from "@util/constants";

const Select = styled.select<{ color?: Color }>`
  width: 100%;
  color: ${colors.navy};
  padding: 0 10px;
  height: 47px;
  ${({ color }) => color && `background-color: ${colors[color]};`}
`;

interface Props {
  name: string;
  defaultTitle: string;
  options: (SelectOption | undefined)[];
  optionState: [SelectOption | undefined, Dispatch<SetStateAction<SelectOption | undefined>>];
  disabled?: boolean;
  color?: Color;
}

const Dropdown = ({ name, options, defaultTitle, optionState, disabled, color }: Props) => {
  const [selectedOption, setSelectedOption] = optionState;

  const handleOnChange = (changeValue: string) => {
    const option = options.find(opt => opt?.id === changeValue);
    if (option) {
      setSelectedOption(option);
    }
  };

  return (
    <Select
      name={name ?? ""}
      id={name ?? ""}
      disabled={disabled}
      onChange={e => handleOnChange(e.target.value)}
      value={selectedOption?.id ?? ""}
      defaultValue=""
      color={color}
    >
      <option value="" disabled>
        {defaultTitle}
      </option>
      {options.map((item, index) => {
        if (item == null) return null;
        return (
          <option value={item.id} key={index}>
            {item.title}
          </option>
        );
      })}
    </Select>
  );
};

export default Dropdown;
