import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { Maybe, SanityCollection, SanityProduct, SanityRange, SanitySlug } from "@graphql-types";
import { assets, colors, colorsRGB, fontWeights, TABLET_BREAKPOINT } from "@util/constants";
import { Container, DesktopContainer, MobileContainer } from "@util/standard";
import { CategorySelector } from "@global";
import MobileFilterList from "./filter/mobileFilterList";
import { collections } from "@util/pagesPaths";
import { checkCollectionProductLength } from "@util/helper";

interface Props {
  data: Maybe<Maybe<SanityCollection>[]> | undefined;
  title: Maybe<string> | undefined;
  rangeSlug: Maybe<SanitySlug> | undefined;
  context?: string;
  collection?: SanityCollection;
  range?: SanityRange;
  mobileTitleOverride?: string;
  products?: SanityProduct[];
}

const CollectionLink = styled(Link)<{ selected?: boolean }>`
  ${({ selected }) =>
    selected ? `font-weight: ${fontWeights.bold}` : `font-weight: ${fontWeights.normal}`};
  padding: 22px 0;
  border-bottom: 1px solid ${colorsRGB.navy(0.4)};
  color: ${colors.navy};

  &:visited {
    color: ${colors.navy};
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    border: none;
  }
`;

const Title = styled.h4`
  margin: 0;
`;

const Icon = styled.img`
  margin-right: 12px;
`;

function CollectionFilterList({
  data,
  title,
  mobileTitleOverride,
  rangeSlug,
  context,
  collection,
  range,
  products,
}: Props) {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  return (
    <>
      <DesktopContainer width="50%" flexDirection="column" padding="150px 25% 0 25%">
        {rangeSlug && (
          <CollectionLink
            to={`/${rangeSlug?.current}`}
            selected={rangeSlug.current != null && context == null}
          >
            All {title}
          </CollectionLink>
        )}
        {data &&
          data.map(collection => {
            if (collection == null) return null;

            return (
              <CategorySelector
                collection={collection}
                context={context}
                key={collection._id}
                products={products}
              />
            );
          })}
      </DesktopContainer>

      <MobileContainer width="100%">
        <Container width="100%" margin="35px auto 5px auto">
          <Icon src={assets.filterIcon} />
          <Title onClick={() => setMobileDrawerOpen(true)}>{mobileTitleOverride ?? "Filter"}</Title>
        </Container>
        <MobileFilterList visible={mobileDrawerOpen} onClose={() => setMobileDrawerOpen(false)}>
          {collection && (
            <CategorySelector
              hideTitle
              context={context}
              collection={collection}
              products={products}
            />
          )}
          {range && (
            <Container flexDirection="column" margin="30px 0 0 0">
              {range.rangeCollections?.map(collection => {
                if (collection == null) return null;

                if (checkCollectionProductLength(collection) === 0) return null;

                return (
                  <CollectionLink
                    to={`${collections}${collection.slug?.current}`}
                    key={collection._id}
                  >
                    <h3 onClick={() => setMobileDrawerOpen(false)}>{collection.title}</h3>
                  </CollectionLink>
                );
              })}
            </Container>
          )}
        </MobileFilterList>
      </MobileContainer>
    </>
  );
}

export default CollectionFilterList;
