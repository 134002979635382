import React, { Dispatch, SetStateAction } from "react";

import { Container } from "@util/standard";
import Button from "./button";
import ModalBox from "./modalBox";

export interface ConfirmationModalProps {
  message?: string;
  confirmText?: string;
  loading?: boolean;
  onConfirm: () => void;
  state: [boolean, Dispatch<SetStateAction<boolean>>];
}

export default function ConfirmationModal({
  message,
  onConfirm,
  loading,
  confirmText,
  state,
}: ConfirmationModalProps) {
  const [visible, setVisible] = state;

  const handleCancelClick = () => setVisible(false);

  return (
    <ModalBox visible={visible} setVisible={setVisible} height="auto" width="auto">
      <Container
        borderRadius={8}
        padding="50px"
        flexDirection="column"
        backgroundColor="white"
        maxWidth="300px"
        justifyContent="center"
        alignItems="center"
      >
        <p style={{ textAlign: "center" }}>{message}</p>

        <Container>
          <Button margin="0 25px 0 0" theme="bordered" linkText="No" onClick={handleCancelClick} />
          <Button loading={loading} linkText={confirmText ?? "Yes"} onClick={onConfirm} />
        </Container>
      </Container>
    </ModalBox>
  );
}
