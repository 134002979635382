import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { isBrowser } from "@util/helper";
import { useCheckScreenWidth } from "@util/hooks";

interface ParallaxProps {
  id?: string;
  speed?: number;
  className?: string;
  children: JSX.Element;
}

export default function Parallax({
  className,
  children,
  speed = 1,
  id = "parallax"
}: ParallaxProps) {
  const trigger = useRef<HTMLDivElement>(null); // will trigger the animation
  const target = useRef<HTMLDivElement>(null); // will be animated
  const { isMobileWidth, isTabletWidth } = useCheckScreenWidth();

  useEffect(() => {
    if (!isBrowser || !trigger.current || !target.current || isMobileWidth || isTabletWidth) return;
    gsap.registerPlugin(ScrollTrigger);

    const yOffset = 100 * speed;
    const ctx = gsap.context(() => {
      gsap.timeline({
        scrollTrigger: {
          id: id,
          trigger: trigger.current,
          scrub: 0.5,
          start: "top bottom",
          end: "bottom top",
          onUpdate: e => {
            const progress = e.progress * yOffset;
            const translateY = progress;
            const update = () => gsap.to(target.current, { y: translateY, duration: 0.1, ease: "none" });
            update();
          },
          fastScrollEnd: true
        }
      });
    });

    return () => ctx.revert();
  }, [id, speed, isMobileWidth, isTabletWidth]);

  if (isMobileWidth || isTabletWidth) return children;

  return (
    <div ref={trigger} className={className}>
      <div ref={target} className="para-target">
        {children}
      </div>
    </div>
  );
}
