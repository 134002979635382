import React from "react";
import styled from "styled-components";

import { Container } from "@util/standard";
import { Maybe, SanityProduct, SanityRelatedProducts } from "@graphql-types";
import { ProductCard, StandardGridLayout } from "@global";
import { TABLET_BREAKPOINT } from "@util/constants";

interface Props {
  data: Maybe<SanityRelatedProducts> | undefined;

  categoryCollection: Maybe<SanityProduct[]>;
}

const Title = styled.h2`
  padding: 45px 39% 0 22%;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 0px;
    width: 100%;
  }
`;

function RelatedProducts({ data, categoryCollection }: Props) {
  const Products = () => {
    const hasProducts = data && data.products && data.products.length === 3;

    if (hasProducts || categoryCollection) {
      return (
        <Container width="100%">
          <StandardGridLayout>
            {hasProducts && data?.products
              ? data?.products?.map((product, index) => {
                  if (product == null || index > 2) return null;

                  return <ProductCard data={product} key={product.id} hasBorderLeft={index > 0} />;
                })
              : categoryCollection?.map((product, index) => {
                  if (product == null || index > 2) return null;

                  return <ProductCard data={product} key={product.id} hasBorderLeft={index > 0} />;
                })}
          </StandardGridLayout>
        </Container>
      );
    }
    return null;
  };

  return (
    <Container tabletFlexDirection="column">
      <Container width="29%" tabletWidth="80%" tabletMargin="auto">
        <Title>{(data && data.title) ?? "Related Products"}</Title>
      </Container>
      <Products />
    </Container>
  );
}

export default RelatedProducts;
