import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const HeaderQuery = () => {
  const { allSanityNavigation }: Query = useStaticQuery(graphql`
    query HeaderQuery {
      allSanityNavigation {
        nodes {
          region {
            countryCode
          }
          buttonText
          logo {
            asset {
              url
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          links {
            ... on SanityImageMenu {
              _key
              _type
              menuTitle
              menuLinks {
                ...sanityImageLink
              }
            }
            ... on SanityLink {
              ...sanityLink
            }
          }
          pageLinks {
            _key
            menuTitle
            _rawMenuLinks
            _type
            menuLinks {
              ...sanityLink
            }
          }
        }
      }
    }
  `);

  const regionHeader = allSanityNavigation.nodes.filter(header => {
    if (header == null) return null;
    return header.region?.countryCode === process.env.GATSBY_REGION;
  });

  if (regionHeader[0] == null) return null;

  return regionHeader[0];
};

export default HeaderQuery;
