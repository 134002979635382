import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import { Collapse } from "react-collapse";

import { assets, colorsRGB } from "@util/constants";
import { Container, P } from "@util/standard";
import { Maybe } from "@graphql-types";

interface Props {
  children: ReactNode;
  noBorderTop?: boolean;
  noBorderBottom?: boolean;
  text: Maybe<string> | undefined;
  borderColor?: string;
  whiteVersion?: boolean;
}

const StyledContainer = styled(Container)<{}>`
  flex-direction: column;
  cursor: pointer;
  border-bottom: 1px solid ${colorsRGB.navy(0.2)};
  padding: 30px 0;
`;

const StyledCollapse = styled(Collapse)`
  border-top: 1px solid ${colorsRGB.black(0.2)};
  border-bottom: 1px solid ${colorsRGB.black(0.2)};
`;

const Icon = styled.img<{ flipped: boolean }>`
  ${({ flipped }) => (flipped ? `transform: rotateZ(180deg);` : `transform: rotateZ(0deg);`)};
  width: 15px;
  transition: all 0.2s;
`;

function Accordion({ children, text }: Props) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <StyledContainer width="100%">
      {text && (
        <Container justifyContent="space-between" onClick={() => setIsExpanded(!isExpanded)}>
          <P margin="0px" fontSize={20} fontWeight="bold">
            {text}
          </P>
          <Icon flipped={isExpanded} src={assets.arrowDown} />
        </Container>
      )}
      <StyledCollapse isOpened={isExpanded} onClick={() => setIsExpanded(!isExpanded)}>
        {children}
      </StyledCollapse>
    </StyledContainer>
  );
}

export default Accordion;
